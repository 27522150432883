import clsx from 'clsx'
import {FC, PropsWithChildren, useMemo} from 'react'
import {HeaderProps} from 'react-table'
import {Inspection} from '../../core/_models'
import mainClasses from './../../../,,/../../main.module.css'

type Props = {
  className?: string
  title?: string
  tableProps: PropsWithChildren<HeaderProps<Inspection>>
}
const InspectionCustomHeader: FC<Props> = ({className, title, tableProps}) => {
  const id = tableProps.column.id

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className,
      )}
    >
      <span className={`m-2 ${mainClasses['inspection-text']}  ${mainClasses['inspection-muted-text']} ${mainClasses['itext-500']} ${mainClasses['ifs-13']}`}>{title}</span>
    </th>
  )
}

export {InspectionCustomHeader}
