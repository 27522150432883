import React from 'react'

import { QuestionSimpleField } from './component/QuestionSimpleField'
import { QuestionInformationLabel } from './component/QuestionInformationLabel'
import { useQueryResponseLoading } from './QuestionFormProvider'
import { DarkDotItemList } from './../../../UI/DarkDotItemList'

import mainClasses from './../../../main.module.css'

const EngineCompartment = () => {
	const isLoading = useQueryResponseLoading()

	return (
		<>
			{ !isLoading &&
				<div className='row'>
					<div className='card card-xxl-stretch'>
						{/* begin::Header */}
						<div className='card-header border-0 d-flex text-center w-100 align-content-center justify-content-center'>
							{/* begin::Title */}
							<h3 className={`${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['ifs-23']}`}>7. Komora silnika</h3>
							{/* end::Title */}

							{/* begin::Toolbar */}
							<div className='card-toolbar'>
								{/* begin::Menu */}
								{/* end::Menu */}
							</div>
							{/* end::Toolbar */}
						</div>
						{/* end::Header */}

						<div className='card-body'>
							<QuestionInformationLabel />
							<QuestionSimpleField title="1. Kontrola poziomu oleju silnikowego" dictId={103} />
							<QuestionSimpleField title="2. Kontrola poziomu i stanu płynu hamulcowego" dictId={104} />
							<QuestionSimpleField title="3. Kontrola poziomu, stężenia i stanu płynu chłodniczego" dictId={105} />
							<QuestionSimpleField dictId={106}>4. Sprawdzenie szczelności pod kątem wycieków:
									<DarkDotItemList items={['silnik', 'skrzynia', 'układ chłodzenia', 'układ hamulcowy']} />
							</QuestionSimpleField>
							<QuestionSimpleField title="5. Kontrola działania wentylatora chłodzącego silnik" dictId={107} />
							<QuestionSimpleField title="6. Kontrola stanu płynu do spryskiwaczy" dictId={108} />
							<QuestionSimpleField title="7. Kontrola poziomu płynu w układzie wspomagania kierownicy" dictId={109} />
							<QuestionSimpleField title="8. Kontrola poziomu płynu w układzie sterowania sprzęgła" dictId={110} />
							<QuestionSimpleField title="9. Kontrola stanu filtra powietrza" dictId={111} />
							<QuestionSimpleField title="10. Kontrola filtra kabinowego" dictId={112} />
							<QuestionSimpleField title='11. Akumulator: sprawdzenie stanu "test"' dictId={113} />
							<QuestionSimpleField title="12. Kontrola klimatyzacji, luzy, złącza, zawory" dictId={114} />
							<QuestionSimpleField title="13. Kontrola pasa napędu osprzętu silnika - stopień zużycia i napięcia" dictId={115} />

						</div>
					</div>
				</div>}
		</>
	)
}

export default React.memo(EngineCompartment)

