/* eslint-disable react/jsx-no-target-blank */
import { AsideMenuItem } from './AsideMenuItem'
import {useAuth} from './../../../../app/modules/auth/core/Auth'

export function AsideMenuMain() {
    const {currentUser} = useAuth()

	return (
		<>
			<div className='menu-item'>
				<div className='menu-content pt-8 pb-2'>
					<span className='menu-section text-muted text-uppercase fs-8 ls-1' style={{fontFamily: 'Poppins', fontWeight: '400', fontSize: '12px' }}>MENU</span>
				</div>
			</div>
			{currentUser?.role === 'ROLE_ADMIN' && <AsideMenuItem
				to='/user-management/users'
				icon='/media/inspection/user.svg'
				iconActive='/media/inspection/userActive.svg'
				title='Użytkownicy'
			/>}
			{currentUser?.role === 'ROLE_ADMIN' && <AsideMenuItem
				to='/company-management/company'
				icon='/media/inspection/firma.svg'
				iconActive='/media/inspection/firmaActive.svg'
				title='Firmy'
			/>}
			<AsideMenuItem
				to='/inspection-management/inspection'
				icon='/media/inspection/inspection.svg'
				iconActive='/media/inspection/inspectionActive.svg'
				title='Inspekcje'
			/>
		</>
	)
}