import { useMemo } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { CustomHeaderColumn } from '../table/columns/CustomHeaderColumn'
import { CustomRow } from '../table/columns/CustomRow'
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'
import { inspectionColumns, inspectionMobileColumn } from './columns/_columns'
import { Inspection } from '../core/_models'
import { InspectionListLoading } from '../components/loading/InspectionListLoading'
import  Pagination  from '../components/pagination/Pagination'
import { KTCardBody } from '../../../../../_metronic/helpers'
import { useViewport } from './../../../UI/ViewportContext'

const InspectionTable = () => {
	const inspection = useQueryResponseData()
	const isLoading = useQueryResponseLoading()
	
	const { width } = useViewport()
	
	let inspectionColumnComponent = inspectionColumns;
	if(width < 900){
		inspectionColumnComponent = inspectionMobileColumn	
	}
	
	const data = useMemo(() => inspection, [inspection])

	const columns = useMemo(() => inspectionColumnComponent, [width < 900])
	const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
		columns,
		data,
	})

	return (
		<KTCardBody className='py-4'>
			<div className='table-responsive'>
				<table
					id='kt_table_users'
					className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
					{...getTableProps()}
				>
					<thead>
						<tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
							{headers.map((column: ColumnInstance<Inspection>) => (
								<CustomHeaderColumn key={column.id} column={column} />
							))}
						</tr>
					</thead>
					<tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
						{rows.length > 0 ? (
							rows.map((row: Row<Inspection>, i) => {
								prepareRow(row)
								return <CustomRow row={row} key={`row-${i}-${row.id}`} />
							})
						) : (
								<tr>
									<td colSpan={7}>
										<div className='d-flex text-center w-100 align-content-center justify-content-center'>
											Nie znaleziono pasującego wyniku
                  						</div>
									</td>
								</tr>
							)}
					</tbody>
				</table>
			</div>
			<Pagination />
			{isLoading && <InspectionListLoading />}
		</KTCardBody>
	)
}

export { InspectionTable }
