import { QuestionInputRadioElement } from './QuestionInputRadioElement'
import {
	WithChildren,
} from '../../../../../../_metronic/helpers'
import mainClasses from './../../../../main.module.css'

type Props = {
	title?: string
	dictId: number
}

const QuestionSimpleField: React.FC<Props & WithChildren> = ({ title, dictId, children }) => {
	return (
		<>
			<div className='row d-flex my-5 align-content-start justify-content-start'>
				<h3 className={`text-start ${mainClasses['inspection-text']} ${mainClasses['itext-600']}${mainClasses['inspection-dark']} ${mainClasses['ifs-14']}`}>
					{title && title}
					{!title && children}</h3>
			</div>
			<div className='inline'>
				<QuestionInputRadioElement value="p" name="P" dictId={dictId} />
				<QuestionInputRadioElement value="w" name="N" dictId={dictId} />
				<QuestionInputRadioElement value="n" name="N/D" dictId={dictId} />
			</div>
			<div className="separator separator-dashed my-5"></div>
		</>
	)
}

export { QuestionSimpleField }
