/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateAdminRoutes } from './PrivateAdminRoutes'
import { PrivateUserRoutes } from './PrivateUserRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
	const { currentUser } = useAuth()

	return (
		<BrowserRouter basename={PUBLIC_URL}>
			<Routes>
				<Route element={<App />}>
					<Route path='error/*' element={<ErrorsPage />} />
					<Route path='logout' element={<Logout />} />
					{
						currentUser?.role === 'ROLE_USER' &&
						<>
							<Route path='/*' element={<PrivateUserRoutes />} />
							<Route index element={<Navigate to='/inspection-management' />} />
						</>
					}
					{
						currentUser?.role === 'ROLE_ADMIN' &&
						<>
							<Route path='/*' element={<PrivateAdminRoutes />} />
							<Route index element={<Navigate to='/user-management' />} />
						</>
					}
					{
						!currentUser &&
						<>
							<>
								<Route path='auth/*' element={<AuthPage />} />
								<Route path='*' element={<Navigate to='/auth' />} />
							</>
						</>
					}

				</Route>
			</Routes>
		</BrowserRouter>
	)
}

export { AppRoutes }
