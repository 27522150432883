import { InspectionEditFormBrandsModelFields } from './../../../inspection-detail/inspection-edit-form/InspectionEditFormBrandsModelFields'
import { InspectionListFilter } from './InspectionListFilter'

import { InspectionListSearchComponent } from './InspectionListSearchComponent'
import classesMain from './../../../../main.module.css';
import { PageTitle } from './../../../../../../_metronic/layout/core'

const InspectionListHeader = () => {

	return (
		<>
			<div className="d-flex row mb-10">
				<div className="text-center text-md-start col-12 col-md-8 d-none d-lg-flex">
					<h2 className={`${classesMain['inspection-text']} ${classesMain['inspection-dark-blue']} ${classesMain['inspection-text-27']}`}>Lista inspekcji</h2>
				</div>
				<PageTitle>
					Lista inspekcji
				</PageTitle>

				<div className="d-flex justify-content-center justify-content-md-end col-12 col-md-4 mt-10 mt-md-0">
					<InspectionListSearchComponent />
					<InspectionEditFormBrandsModelFields>
						<InspectionListFilter />
					</InspectionEditFormBrandsModelFields>
				</div>
			</div>
		</>
	)
}

export { InspectionListHeader }
