import { useAuth } from '../../../../app/modules/auth'
import Avatar from 'react-avatar';
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../helpers'
import classesStyle from './../header/HeaderWrapper.module.css';

/* eslint-disable jsx-a11y/anchor-is-valid */
const AsideToolbar = () => {
	const { currentUser } = useAuth()

	return (
		<>

			{/* end::Brand */}
			<div className="d-flex align-items-sm-center justify-content-between py-1">
				{/* begin::Logo */}
				<Link className={'m-5'} to='/inspection-management/inspection'>
					<KTSVG
						path='/media/svg/brand-logos/otomoto.svg'
						svgClassName={`mh-150px ${classesStyle['logo']}`}
					/>
				</Link>
				{/* end::Logo */}
				<div
					className='btn btn-icon btn-active-color-primary m-3 d-lg-none'
					id='kt_aside_mobile_toggle'
				>
					<KTSVG
						path='/media/inspection/arrLeft.svg'
						className='svg-icon-1 me-n1 minimize-default' svgClassName={`${classesStyle['toggleButton']}`}
					/>
				</div>
			</div>
			{/*begin::User*/}
			{/*begin::Symbol*/}
			<div className="d-flex align-items-sm-center justify-content-center py-1">
				<Avatar name={currentUser?.surname + " " + currentUser?.name} maxInitials={2} round='12px' size='80' color='#E6F1FB' fgColor="#0072D5" />
			</div>
			{/*end::Symbol*/}
			{/*begin::Username* LINK do podgładu użytkownika*/}
			<div className='d-flex align-items-sm-center justify-content-center py-1'>
				<span className='text-white fs-6 fw-bold' style={{ fontFamily: "Poppins" }}>
					{currentUser?.surname} {currentUser?.name}
				</span>
			</div>
			{/*end::Username*/}
			<div className='d-flex align-items-sm-center justify-content-center py-1' style={{ fontFamily: "Poppins" }} >
				{/*begin::Description*/}
				{currentUser?.role === 'ROLE_ADMIN' && <span className='text-gray-600 fw-bold d-block fs-8 mb-1'>Administrator</span>}
				{currentUser?.role === 'ROLE_USER' && <span className='text-gray-600 fw-bold d-block fs-8 mb-1'>Inspektor</span>}

				{/*end::Description*/}
			</div>

			{/*end::User*/}
		</>
	)
}

export { AsideToolbar }
