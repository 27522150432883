import {Route, Routes} from 'react-router-dom'

import {ForgotPassword} from './components/ForgotPassword'
import {ChangePassword} from './components/ChangePassword'
import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='create-password/:token' element={<ChangePassword isCreatePassword={true} />} />
      <Route path='change-password/:token' element={<ChangePassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
