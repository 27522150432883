/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useLayout } from '../../core'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../helpers'
import { AsideMenu } from './AsideMenu'
import { AsideToolbar } from './AsideToolbar'
import { useAuth } from '../../../../app/modules/auth'


const AsideDefault: FC = () => {
	const { classes } = useLayout()
	const { logout } = useAuth();

	return (
		<div
			id='kt_aside'
			className='aside'
			data-kt-drawer='true'
			data-kt-drawer-name='aside'
			data-kt-drawer-activate='{default: true, lg: false}'
			data-kt-drawer-overlay='true'
			data-kt-drawer-width="{default:'200px', '300px': '250px'}"
			data-kt-drawer-direction='start'
			data-kt-drawer-toggle='#kt_aside_mobile_toggle'
		>
			{/* begin::Aside Toolbarl */}
			<div className='aside-toolbar flex-column-auto' id='kt_aside_toolbar'>
				<AsideToolbar />
			</div>
			{/* end::Aside Toolbarl */}
			{/* begin::Aside menu */}
			<div className='aside-menu flex-column-fluid'>
				<AsideMenu asideMenuCSSClasses={classes.asideMenu} />
			</div>
			{/* end::Aside menu */}

			{/* begin::Footer */}
			<div className='aside-footer flex-column-auto py-5' id='kt_aside_footer'>
				{/*begin::User menu*/}
				<div className='row'>
					{/*begin::Action*/}
					<div className="col-md-12">
						<a onClick={logout} style={{ background: 'rgba(204, 204, 204, 0.2)' }} className='btn btn-light-dark w-100 border-0'>
							<span className='menu-icon'>
								<SVG src={toAbsoluteUrl("/media/inspection/arrlogout.svg")} />
							</span>
							<span className='menu-title' style={{color: '#CCCCCC'}}>Wyloguj</span>
						</a>
					</div>
					{/*end::Action*/}
				</div>
				{/*end::User menu*/}
			</div>
			{/* end::Footer */}
		</div>
	)
}

export { AsideDefault }
