import { FC } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { checkIsActive, KTSVG, WithChildren } from '../../../helpers'

type Props = {
	to: string
	title: string
	icon?: string
	iconActive?: string
	fontIcon?: string
	hasBullet?: boolean
}

const AsideMenuItem: FC<Props & WithChildren> = ({
	children,
	to,
	title,
	icon,
	iconActive,
	fontIcon,
	hasBullet = false,
}) => {
	const { pathname } = useLocation()
	const isActive = checkIsActive(pathname, to)

	return (
		<div className='menu-item'>
			<Link className={clsx('menu-link without-sub', { active: isActive })} to={to}>
				{hasBullet && (
					<span className='menu-bullet'>
						<span className='bullet bullet-dot'></span>
					</span>
				)}
				{(!isActive && icon) && (
					<span className='menu-icon'>
						<KTSVG path={icon} className='svg-icon-2' />
					</span>
				)}
				{(isActive && iconActive) && (
					<span className='menu-icon'>
						<KTSVG path={iconActive} className='svg-icon-2' />
					</span>
				)}
				{fontIcon && <i className={clsx('bi fs-3', fontIcon)}></i>}
				<span className='menu-title'>{title}</span>
			</Link>
			{children}
		</div>
	)
}

export { AsideMenuItem }
