import React from 'react'

import { QuestionSimpleField } from './component/QuestionSimpleField'
import { QuestionInformationLabel } from './component/QuestionInformationLabel'
import { useQueryResponseLoading } from './QuestionFormProvider'
import { DarkDotItemList } from './../../../UI/DarkDotItemList'
import mainClasses from './../../../main.module.css'

const SteeringSystemChassis = () => {
	const isLoading = useQueryResponseLoading()

	return (
		<>
			{ !isLoading &&
				<div className='row'>
					<div className='card card-xxl-stretch'>
						{/* begin::Header */}
						<div className='card-header border-0 d-flex text-center w-100 align-content-center justify-content-center'>
							{/* begin::Title */}
							<h3 className={`${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['ifs-23']}`}>10. Układ kierowniczy i podwozie</h3>
							{/* end::Title */}

							{/* begin::Toolbar */}
							<div className='card-toolbar'>
								{/* begin::Menu */}
								{/* end::Menu */}
							</div>
							{/* end::Toolbar */}
						</div>
						{/* end::Header */}

						<div className='card-body'>
							<QuestionInformationLabel />
							<QuestionSimpleField title="1. Wycieki oleju ze skrzyni przekładniowej i mechanizmu różnicowego" dictId={130} />
							<QuestionSimpleField title="2. Wycieki z hydraulicznego układu sterowania sprzęgłem" dictId={131} />
							<QuestionSimpleField dictId={132}>3. Kontrola układu kierowniczego:
									<DarkDotItemList items={['przekładnia', 'końcówki', 'sworznie']} />
							</QuestionSimpleField>
							<QuestionSimpleField dictId={133}>4. Wał napędowy:
									<DarkDotItemList items={['półosie', 'krzyżaki']} />
							</QuestionSimpleField>
							<QuestionSimpleField dictId={134}>5. Układ wydechowy:
									<DarkDotItemList items={['kolektor', 'katalizator', 'tłumiki']} />
							</QuestionSimpleField>
							<QuestionSimpleField title="6. Kontrola poziomu oleju w mechanizmie różnicowym/skrzyni przekładniowej" dictId={135} />
							<QuestionSimpleField title="7. Kontrola zewnętrznego mechanizmu zmiany biegów" dictId={136} />
							<QuestionSimpleField title="8. Kontrola zawieszenia z uwzględnieniem łożysk i tulei" dictId={137} />
							<QuestionSimpleField title="9. Korozja/uszkodzenia elementów zawieszenia" dictId={138} />
							<QuestionSimpleField title="10. Kontrola przewodów paliwowych i hamulcowych" dictId={139} />
							<QuestionSimpleField title="11. Linka hamulca ręcznego/kontrola mechanizmu" dictId={140} />
							<QuestionSimpleField title="14. Cylinderki i zaciski hamulcowe: szczelność" dictId={151} />
							<QuestionSimpleField title="15. Śruby mocujące koła" dictId={152} />
							<QuestionSimpleField title="16. Kontrola stanu łożysk" dictId={153} />
						</div>
					</div>
				</div>}
		</>
	)
}

export default React.memo(SteeringSystemChassis)

