import React, { useEffect } from 'react'
import { Link, useNavigate, useParams } from "react-router-dom";
import { KTSVG, isNotEmpty, ID } from '../../../../../_metronic/helpers'
import { confirmInspection } from './../../inspection-list/core/_requests'

import { useSubmitValidationContext } from './core/InspectionSubmitValidation'

import { useQueryResponseLoading } from './QuestionFormProvider'
import mainClasses from './../../../main.module.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ConfirmInspection = () => {
	const isLoading = useQueryResponseLoading()

	const { id } = useParams();

	const navigate = useNavigate();
	const { errorsQuestion, setErrorsQuestion } = useSubmitValidationContext();

	const confirmInspectionSubmit = async () => {
		try {
			if (isNotEmpty(id)) {
				const response = await confirmInspection(id as ID);

				if (response?.success) {
					navigate('/inspection-management/inspection', { replace: true });
				} else {
					if(response?.errors?.fields){
                        let errorMsg = '';
                        if(response?.errors?.fields?.includes("country")){errorMsg = errorMsg + ' Kraj';}
                        if(response?.errors?.fields?.includes("mileage")){errorMsg = errorMsg + ' Przebieg';}
                        if (errorMsg==''){
                            toast.error("Sprawdź pola podstawowe inspekcji - nie wszystkie zostały wypełnione");
                        }
                        else{
                            toast.error("Następujące pola podstawowe nie zostały wypełnione:" + errorMsg);
                        }
					}

					if (response?.errors?.question) {
						setErrorsQuestion(response?.errors?.question);
                        toast.error("Następująca ilość pól nie została uzupełniona " + response?.errors?.question.length);
					}
				}
			}
		} catch (ex) {
			console.error(ex)
		} finally {
		}
	}

	useEffect(() => {
		const element = document.getElementsByClassName('is-invalid');
		if (element[0]) {
			element[0].scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				inline: 'start',
			});
		}
	}, [errorsQuestion])

	return (
		<>
			{ !isLoading &&
				<div className='row m-10'>
					<div className="d-flex justify-content-center justify-content-md-end me-5">
						<Link to={`#`} onClick={confirmInspectionSubmit} className={`btn btn-light-danger w-100 w-md-300px`}>
							<span className={`${mainClasses['itext-600']} ${mainClasses['inspection-text']} ${mainClasses['ifs-14']}`}>Zatwierdź inspekcję</span>
							<KTSVG path="/media/inspection/checkRingRound.svg" className="p-2 svg-icon-1" />
						</Link>
					</div>
				</div>
			}
		</>
	)
}

export default React.memo(ConfirmInspection)

