/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, createContext, useState, Dispatch, SetStateAction } from 'react'

import {
	WithChildren,
} from './../../../../../../_metronic/helpers'

type Props = {
	errorsQuestion: Array<number>,
    setErrorsQuestion: Dispatch<SetStateAction<Array<number>>>
}

const initialState: Props = {
	errorsQuestion: [],
	setErrorsQuestion: ()=> {}
}
const InspectionSubmitValidationContext = createContext<Props>(initialState)

const InspectionSubmitValidation: FC<WithChildren> = ({ children }) => {
    const [errorsQuestion, setErrorsQuestion] = useState<Array<number>>(initialState.errorsQuestion)
	
	return (
		<InspectionSubmitValidationContext.Provider value={{errorsQuestion: errorsQuestion, setErrorsQuestion}}>
			{children}
		</InspectionSubmitValidationContext.Provider>
	)
}

const useSubmitValidationContext = () => useContext(InspectionSubmitValidationContext)

const useSubmitValidation  = () => {
	const { errorsQuestion } = useSubmitValidationContext()
	return errorsQuestion || []
}

export {
	InspectionSubmitValidation,
	useSubmitValidationContext,
	useSubmitValidation
}
