/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import SVG from 'react-inlinesvg'

import { Outlet, Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const AuthLayout = () => {
	useEffect(() => {
		const root = document.getElementById('root')
		if (root) {
			root.style.height = '100%'
		}
		return () => {
			if (root) {
				root.style.height = 'auto'
			}
		}
	}, [])

	return (
		<div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
			{/* begin::Body */}

			<div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order--1'>
				{/* begin::Form */}
				<div className='d-flex flex-center flex-column'>
					<Link to='/' className='mb-10 pt-lg-10'>
						<SVG src={toAbsoluteUrl("/media/inspection/logo.svg")} />
					</Link>
				</div>
				<div className='d-flex flex-center flex-column flex-lg-row-fluid'>

					{/* begin::Wrapper */}
					<div className='w-lg-500px p-10'>
						<Outlet />
					</div>
					{/* end::Wrapper */}
				</div>
				{/* end::Form */}

			</div>
			{/* end::Body */}

			{/* begin::Aside */}
			<div
				className='d-flex flex-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
				style={{ backgroundImage: `url(${toAbsoluteUrl('/media/inspection/auth-bg.png')})` }}>
			</div>
			{/* end::Aside */}
		</div>
	)
}

export { AuthLayout }
