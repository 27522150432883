import { FC, useEffect, DispatchWithoutAction } from 'react'
import { WithChildren } from '../../../_metronic/helpers';
import mainClasses from './../main.module.css'

type Props = {
	title?: Array<string>
	onClickYes: DispatchWithoutAction
	onClickNo: DispatchWithoutAction
	titleYes: string
}

const SmallAskModal: FC<Props & WithChildren> = ({ title, onClickYes, onClickNo, titleYes = 'Tak' }) => {

	useEffect(() => {
		document.body.classList.add('modal-open')
		return () => {
			document.body.classList.remove('modal-open')
		}
	}, [])
	return (
		<>
			<div
				className='modal fade show d-block'
				id='kt_modal_add_user'
				role='dialog'
				tabIndex={-1}
				aria-modal='true'
			>
				<div className='modal-dialog modal-dialog-centered mw-650px'>
					<div className='modal-content'>
						<div className='modal-body mx-5 mx-xl-15 my-7 text-center'>
							{title && title.map((itemTitle, index) => (<h1 key={index} className={`${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['ifs-28']}`}>{itemTitle}</h1>))}
							<div className='d-flex row mt-5'>
								<div className={'col-12 col-md-6'}>
									<button type="button" className={`btn btn-secondary col-6  my-5  w-100 h-50px ${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['ifs-16']}`}
										onClick={onClickNo}>Nie</button>
								</div>
								<div className={'col-12 col-md-6'}>
									<button type="button" className={`btn my-md-5 w-100 btn-light-danger h-50px ${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['ifs-16']}`}
										onClick={onClickYes}>{titleYes}</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='modal-backdrop fade show'></div>
		</>
	)
}

export { SmallAskModal }
