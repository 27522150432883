import axios from 'axios'
import { AuthModel, UserModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL

const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/user/me`
const LOGIN_URL = `${API_URL}/login_check`
const REFRESH_TOKEN_URL = `${API_URL}/token_refresh`

const PASSWORD_URL = `${API_URL}/password`

export function login(email: string, password: string) {
	return axios.post<AuthModel>(LOGIN_URL, {
		"email": email,
		password,
	}, {
		headers: { 'Content-Type': 'application/json' }
	})
}

export function getUserByToken(token: string) {
	return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL,
		{
			headers: { 'Authorization': 'Bearer ' + token }
		}
	)
}

export function getRefreshToken(refresh_token: string) {
	return axios.post<AuthModel>(REFRESH_TOKEN_URL, {
		"refresh_token": refresh_token,
	}, {
		headers: { 'Content-Type': 'application/json' }
	})
}

export function createPassword(password: string, token?: string) {
	return axios.post<{ result: string }>(`${PASSWORD_URL}/create`, {
		token,
		password,
	})
}

export function updatePassword(password: string, token?: string) {
	return axios.post<{ result: string }>(`${PASSWORD_URL}/update`, {
		token,
		password,
	})
}

export function resetPassword(email: string) {
	return axios.post<{ result: string }>(`${PASSWORD_URL}/reset`, {
		email,
	})
}



