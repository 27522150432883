import { FC } from 'react'
import { FormattedDate } from 'react-intl'

import { Inspection } from '../../core/_models'
import { KTSVG } from '../../../../../../_metronic/helpers'


type Props = {
	inspection: Inspection
}

const InspectionCreateModifyDateCell: FC<Props> = ({ inspection }) => (
	<div className='d-flex flex-column'>
		<span><KTSVG path={"/media/inspection/arhiveLoad.svg"} className="m-3" /><FormattedDate value={inspection.createdAt} /></span>
		<span><KTSVG path={"/media/inspection/writeGrey.svg"} className="m-3" /><FormattedDate value={inspection.updatedAt} /></span>
	</div>
)

export { InspectionCreateModifyDateCell }
