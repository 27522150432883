import { FC, useState, useEffect, createContext, useContext, useMemo } from 'react'

import { WithChildren } from '../../../_metronic/helpers';

export type ViewportContextProps = {
	width: number
	height: number
}

const ViewportContext = createContext<ViewportContextProps>({ width: 0, height: 0 })

const ViewportProvider: FC<WithChildren> = ({ children }) => {

	// This is the exact same logic that we previously had in our hook
	const [width, setWidth] = useState<number>(window.innerWidth)
	const [height, setHeight] = useState<number>(window.innerHeight)

	const handleWindowResize = (): void => {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);
	}

	useEffect(() => {
		window.addEventListener("resize", handleWindowResize);
		return () => window.removeEventListener("resize", handleWindowResize);
	}, []);

	/* Now we are dealing with a context instead of a Hook, so instead
	   of returning the width and height we store the values in the
	   value of the Provider */
	return (
		<ViewportContext.Provider value={{ width, height }}>
			{children}
		</ViewportContext.Provider>
	)
}

const useViewport = () => {
	/* We can use the "useContext" Hook to acccess a context from within
	   another Hook, remember, Hooks are composable! */
	const { width, height } = useContext(ViewportContext);
	return { width, height };
}

export {
	ViewportProvider,
	useViewport
}