import React from 'react'
import { DefaultTitle } from './page-title/DefaultTitle'
import { toAbsoluteUrl, KTCard } from './../../../helpers'
import SVG from 'react-inlinesvg'
import { useAuth } from '../../../../app/modules/auth'
import Avatar from 'react-avatar';
import { usePageData } from '../../core/PageData'
import InspectionMenu from './InspectionMenu'
import { useParams } from 'react-router-dom'

const HeaderToolbar = () => {
	const { currentUser } = useAuth()
	const { pageTitle } = usePageData()
	const { id } = useParams()

	return (
		<div className="d-lg-none d-flex">
			<div className="justify-content-center m-7" style={{ width: '10%' }}>
				<div
					className='btn btn-icon btn-active-color-primary text-center'
					id='kt_aside_mobile_toggle'
				>
					<SVG className={''} src={toAbsoluteUrl('/media/icons/duotune/abstract/abs015.svg')} style={{ width: '40px', height: '40px' }} />
				</div>
			</div>
			<DefaultTitle />
			{!pageTitle && <div className="text-end my-10" style={{ width: '65%' }}>
				<span className={'text-capitalize'} style={{
					fontFamily: 'Inter',
					fontStyle: 'normal',
					fontWeight: '700',
					fontSize: '16px',
					color: '#181C32'
				}}>{currentUser?.surname} {currentUser?.name}
				</span>
			</div>}
			{!pageTitle &&
				<div className="m-7" style={{ width: '10%' }} >
					<Avatar name={currentUser?.surname + " " + currentUser?.name} maxInitials={2} round='12px' size='40' color='#E6F1FB' fgColor="#0072D5" />
				</div>
			}
			{(pageTitle && id) &&
				<div className="m-7" style={{ width: '10%' }}>
					<div className='d-flex'>
						{(pageTitle && id) && <InspectionMenu id={id} toggleBtnClass='btn btn-sm btn-icon btn-icon-muted btn-active-icon-danger' />}
					</div>
				</div>
			}
		</div>
	)
}

export default React.memo(HeaderToolbar)


