import { FC } from 'react'
import clsx from 'clsx'

import { useParams } from 'react-router-dom'
import { createQuestion } from './../core/_requests'
import { useResponseDataByDictId } from './../QuestionFormProvider'
import mainClasses from './../../../../main.module.css'
import { useSubmitValidationContext } from './../core/InspectionSubmitValidation'

export type SelectModel = {
	id: string | null
	name: string
}

type Props = {
	name?: string,
	selectList: Array<SelectModel>,
	dictId: number
}

const QuestionSelectElement: FC<Props> = ({ name, dictId, selectList }) => {
	const { id } = useParams();

	const fieldData = useResponseDataByDictId(dictId)
	const { errorsQuestion, setErrorsQuestion } = useSubmitValidationContext()

	// This function is triggered when the select changes
	const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const value = event.target.value;
		
		setErrorsQuestion(errorsQuestion.filter((errorQuestion) => errorQuestion !== dictId))

		createQuestion(String(id).trim(), {
			id: fieldData?.id,
			value: value === '0' ? null : value,
			comment: ''
		});
	};
	return (
		<>
			<label className={`text-center w-100 ${mainClasses['inspection-text']} ${mainClasses['itext-600']}${mainClasses['inspection-dark']} ${mainClasses['ifs-14']}`}>{name}</label>
			<select onChange={selectChange}
				className={clsx(
					`form-control form-control-solid ${mainClasses['select-field']}`,
					{
						'is-invalid': errorsQuestion.includes(dictId),
					}
				)} defaultValue={fieldData?.value}>
				{selectList.map((model) => <option key={model.id} value={model.id || 0}>{model.name}</option>)}
			</select>
		</>
	)
}

export { QuestionSelectElement }
