import axios, { AxiosResponse } from 'axios'
import { Question, QuestionQueryResponse, SaveQuestion } from './_models'

const API_URL = process.env.REACT_APP_API_URL

const QUESTIONS_URL = `${API_URL}/inspection/`

const getQuestion = (inspectionId: string): Promise<QuestionQueryResponse> => {
	return axios
		.get(`${QUESTIONS_URL}${inspectionId.trim()}/questions`)
		.then((d: AxiosResponse<QuestionQueryResponse>) => d.data)
}

const createQuestion = (inspectionId: string, question: SaveQuestion): Promise<Question | undefined> => {
	return axios
		.post(`${QUESTIONS_URL}${inspectionId.trim()}/questions`, {questions: [question]})

}

export { getQuestion, createQuestion }
