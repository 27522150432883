import React from 'react'

import { QuestionSimpleField } from './component/QuestionSimpleField'
import { QuestionInformationLabel } from './component/QuestionInformationLabel'
import { useQueryResponseLoading } from './QuestionFormProvider'
import mainClasses from './../../../main.module.css'

const VehicleInterior = () => {
	const isLoading = useQueryResponseLoading()

	return (
		<>
			{ !isLoading &&
				<div className='row'>
					<div className='card card-xxl-stretch'>
						{/* begin::Header */}
						<div className='card-header border-0 d-flex text-center w-100 align-content-center justify-content-center'>
							{/* begin::Title */}
							<h3 className={`${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['ifs-23']}`}>8. Wnętrze pojazdu</h3>

							{/* end::Title */}

							{/* begin::Toolbar */}
							<div className='card-toolbar'>
								{/* begin::Menu */}
								{/* end::Menu */}
							</div>
							{/* end::Toolbar */}
						</div>
						{/* end::Header */}

						<div className='card-body'>
							<QuestionInformationLabel />
							<QuestionSimpleField title="1. Ogólny stan siedzeń" dictId={120} />
							<QuestionSimpleField title="2. Działanie mechanizmów siedzeń" dictId={121} />
							<QuestionSimpleField title="3. Stan dachu składanego" dictId={122} />
							<QuestionSimpleField title="4. Stan dachu otwieranego" dictId={123} />
							<QuestionSimpleField title="5. Stan tapicerki drzwi, podsufitki, osłon przeciwsłonecznych, rolety dachu" dictId={124} />
							<QuestionSimpleField title="6. Stan pasów bezpieczeństwa/funkcjonowanie zapięcia" dictId={125} />
							<QuestionSimpleField title="7. Mechanizm otwierania i zamykania okien" dictId={126} />
							<QuestionSimpleField title="8. Mechanizm otwierania i zamykania drzwi" dictId={127} />
							<QuestionSimpleField title="9. Stan gumowych nakładek pedałów pod kątem oceny ich zużycia" dictId={128} />
							<QuestionSimpleField title="10. Mocowanie dywanika kierowcy" dictId={129} />

						</div>
					</div>
				</div>}
		</>
	)
}

export default React.memo(VehicleInterior)

