/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, login } from '../core/_requests'
import { useAuth } from '../core/Auth'
import mainClasses from './../../main.module.css';

const loginSchema = Yup.object().shape({
	email: Yup.string()
		.email('Zły format e-mail')
		.min(3, 'Minimum 3 symbole')
		.max(50, 'Maximum 50 symbols')
		.required('E-mail jest wymagany'),
	password: Yup.string()
		.min(3, 'Minimum 8 symbole')
		.max(50, 'Maksymalnie 50 symboli')
		.required('Hasło jest wymagane'),
})

const initialValues = {
	email: '',
	password: '',
}

export function Login() {
	const [loading, setLoading] = useState(false)
	const { saveAuth, setCurrentUser } = useAuth()
	const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

	const formik = useFormik({
		initialValues,
		validationSchema: loginSchema,
		onSubmit: async (values, { setStatus, setSubmitting }) => {
			setLoading(true)
			try {
				const { data: auth } = await login(values.email, values.password)
				saveAuth(auth)
				const { data: user } = await getUserByToken(auth.token)
				setCurrentUser(user)
			} catch (error) {
				console.error(error)
				saveAuth(undefined)
				setStatus('The login details are incorrect')
				setSubmitting(false)
				setLoading(false)
				setHasErrors(true)
			}
		},
	})

	return (
		<form
			className='form w-100'
			onSubmit={formik.handleSubmit}
			noValidate
			id='kt_login_signin_form'
		>
			<div className='text-center mb-10'>
				{/* begin::Title */}
				<h1 className={`${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['inspection-text-27']}`}>Witaj</h1>
				{/* end::Title */}

				{/* begin::Link */}
				<div className={`${mainClasses['itext-600']} ${mainClasses['inspection-text']} ${mainClasses['inspection-muted-text']} ${mainClasses['inspection-text-14']}`}>
					System zbierania danych inspekcji
        </div>
				{/* end::Link */}
			</div>
			{hasErrors === true && (
				<div className='mb-lg-10 alert alert-danger'>
					<div className={`${mainClasses['itext-600']} ${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['inspection-text-14']}`}>
						Dane logowania są nieprawidłowe, spróbuj ponownie
          			</div>
				</div>
			)}

			{/* begin::Form group */}
			<div className={`fv-row mb-8`}>
				<input
					placeholder='Email'
					{...formik.getFieldProps('email')}
					className={clsx(
						`form-control bg-transparent ${mainClasses['inspection-field']}`,
						{ 'is-invalid': formik.touched.email && formik.errors.email },
						{
							'is-valid': formik.touched.email && !formik.errors.email,
						}
					)}
					type='email'
					name='email'
					autoComplete='off'
				/>
				{formik.touched.email && formik.errors.email && (
					<div className='fv-plugins-message-container'>
						<div className={`fv-help-block ${mainClasses['itext-600']} ${mainClasses['inspection-text']} ${mainClasses['inspection-text-14']}`}>
							<span role='alert'>{formik.errors.email}</span>
						</div>
					</div>
				)}
			</div>
			{/* end::Form group */}

			{/* begin::Form group */}
			<div className='fv-row mb-3'>
				<input
					placeholder='Hasło'
					type='password'
					autoComplete='off'
					{...formik.getFieldProps('password')}
					className={clsx(
						`form-control bg-transparent ${mainClasses['inspection-field']}`,
						{
							'is-invalid': formik.touched.password && formik.errors.password,
						},
						{
							'is-valid': formik.touched.password && !formik.errors.password,
						}
					)}
				/>
				{formik.touched.password && formik.errors.password && (
					<div className='fv-plugins-message-container'>
						<div className={`fv-help-block ${mainClasses['itext-600']} ${mainClasses['inspection-text']} ${mainClasses['inspection-text-14']}`}>
							<span role='alert'>{formik.errors.password}</span>
						</div>
					</div>
				)}
			</div>
			{/* end::Form group */}

			{/* begin::Wrapper */}
			<div className='d-flex flex-end flex-wrap gap-3 fs-base fw-semibold mb-5'>
				<div />
				{/* begin::Link */}
				<Link to='/auth/forgot-password'>
					<span className={`${mainClasses['inspection-text']} ${mainClasses['itext-600']} ${mainClasses['inspection-accent-red']} ${mainClasses['inspection-text-14']}`}>Zapomniałeś hasła ?</span>
				</Link>
				{/* end::Link */}
			</div>
			{/* end::Wrapper */}

			{/* begin::Action */}
			<div className='d-grid mb-10'>
				<button
					type='submit'
					id='kt_sign_in_submit'
					className={`btn btn-danger`}
					disabled={formik.isSubmitting || !formik.isValid}>
					{!loading && <span className={`${mainClasses['inspection-text']} ${mainClasses['itext-600']} ${mainClasses['inspection-text-14']}`}>Zaloguj się</span>}
					{loading && (
						<span className={`indicator-progress ${mainClasses['inspection-text']} ${mainClasses['itext-600']} ${mainClasses['inspection-text-14']}`} style={{ display: 'block' }}>
							Proszę czekać...
							<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
						</span>
					)}
				</button>
			</div>
			{/* end::Action */}


		</form>
	)
}
