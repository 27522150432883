import { FC } from 'react'

type Props = {
	valid?: boolean
}

const InspectionValidationCell: FC<Props> = ({ valid }) => {
	return (
		<>
			{valid && <div style={{ color: '#469CF0' }}>Spełniona</div>}
			{!valid && <div style={{ color: '#181C32' }}>Nie spełniona</div>}
		</>
	)
}

export { InspectionValidationCell }
