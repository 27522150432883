/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, createContext, useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import {
	QUERIES,
	WithChildren,
} from './../../../../../_metronic/helpers'
import { getCompanyById } from './../../../company-management/company-list/core/_requests'
import { Company } from './../../../company-management/company-list/core/_models'
import { User} from './../../../company-management/company-detail/users-list/core/_models'

import { initialQueryResponse } from './../../inspection-list/core/_models'
import { useAuth } from '../../../../../app/modules/auth'

type QueryResponseContextProps = {
	response?: Company
	refetch: () => void
	isSelectModelLoading: boolean
}

function createResponseContext(initialState: QueryResponseContextProps) {
	return createContext(initialState)
}

const QueryResponseContext = createResponseContext(initialQueryResponse)

const ModelUsersListSelectProvider: FC<WithChildren> = ({ children }) => {
	const { currentUser } = useAuth()

	const {
		refetch,
		isFetching: modelIsFetching,
		data: response,
	} = useQuery(
		`${QUERIES.COMPANY_LIST}-company-${currentUser?.companyId}`,
		() => {
			return getCompanyById(currentUser?.companyId);
		},
		{ cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
	)

	return (
		<QueryResponseContext.Provider value={{ refetch, response, isSelectModelLoading: modelIsFetching }}>
			{children}
		</QueryResponseContext.Provider>
	)
}

const useQueryUserResponse = () => useContext(QueryResponseContext)

const useModelListSelectData = (): Array<User> => {
	const { response } = useQueryUserResponse()
	if (!response) {
		return []
	}

	return response.accounts || []
}


export {
	ModelUsersListSelectProvider,
	useQueryUserResponse,
	useModelListSelectData,
}
