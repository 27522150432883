import { FC } from 'react'
import { createQuestion } from './../core/_requests'
import { useParams } from 'react-router-dom'
import { useResponseDataByDictId } from './../QuestionFormProvider'
import { useSubmitValidationContext } from './../core/InspectionSubmitValidation'
import clsx from 'clsx'

import mainClasses from './../../../../main.module.css'

type Props = {
	title?: string
	dictId: number
}

const QuestionYesNoField: FC<Props> = ({ title, dictId }) => {
	const { id } = useParams();
	const fieldData = useResponseDataByDictId(dictId)
	
	const { errorsQuestion, setErrorsQuestion } = useSubmitValidationContext();
	
	const onChange = (e: React.FormEvent<HTMLInputElement>) => {
		const value = Number(e.currentTarget.value);
		
		setErrorsQuestion(errorsQuestion.filter((errorQuestion) => errorQuestion !== dictId))

		createQuestion(String(id).trim(), {
			id: fieldData?.id,
			value: !!value,
			comment: ''
		});
	}

	return (
		<>
			<div className='col-xl-6 d-flex w-100 align-content-start justify-content-start'>
				<h3  className={`${mainClasses['inspection-text']}  ${mainClasses['itext-600']}  ${mainClasses['inspection-dark']} ${mainClasses['ifs-14']}`}>{title}</h3>
			</div>
			<div 
				className={clsx(
						`col-xl-6 d-flex w-100 align-content-start justify-content-start`,
						{
							'form-control is-invalid': errorsQuestion.includes(dictId),
						}
					)}>
				<input type="radio" className="btn-check" name={`${dictId}`} id={`${dictId}-yes`} onChange={onChange} autoComplete="off" value={1} defaultChecked={fieldData?.value} />
				<label className={`btn btn-sm btn-light-danger m-2 ${mainClasses['itext-600']} ${mainClasses['question-bool-btn']}`} htmlFor={`${dictId}-yes`}>Tak</label>
				<input type="radio" className="btn-check" name={`${dictId}`} id={`${dictId}-no`} onChange={onChange} autoComplete="off" value={0} defaultChecked={!fieldData?.value} />
				<label  className={`btn btn-sm btn-light-danger m-2 ${mainClasses['itext-600']} ${mainClasses['question-bool-btn']}`} htmlFor={`${dictId}-no`}>Nie</label>
			</div>
			

		</>
	)
}

export { QuestionYesNoField }