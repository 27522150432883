import React from 'react'
import clsx from 'clsx'
import { toAbsoluteUrl } from '../../../helpers'
import { usePageData } from '../../core/PageData'
import { Link } from "react-router-dom";
import SVG from 'react-inlinesvg'

/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
	id: string
	toggleBtnClass?: string
	toggleBtnIconClass?: string
	menuPlacement?: string
	menuTrigger?: string
}

const InspectionMenu = ({
	toggleBtnClass = '',
	menuPlacement = 'bottom-end',
	menuTrigger = "{default: 'click', lg: 'hover'}",
}: Props) => {
	const { pageToolMenuItems } = usePageData()
	return (
		<>
			{/* begin::Menu toggle */}
			<a
				href='#'
				className={clsx('btn', toggleBtnClass)}
				data-kt-menu-trigger={menuTrigger}
				data-kt-menu-attach='parent'
				data-kt-menu-placement={menuPlacement}
				style={{ width: '40px', height: '40px' }}
			>
				<SVG
					src={toAbsoluteUrl('/media/inspection/inspectionMenu.svg')}
					className={''} 
				/>
			</a>
			{/* begin::Menu toggle */}

			{/* begin::Menu */}
			<div
				className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
				data-kt-menu='true'
			>
				{pageToolMenuItems?.map((item, index) => (
					<div key={index} className='menu-item px-3 my-0'>
						<Link
							to={item.href ? item.href : '#'}
							onClick={item.itemOnClick}
							className={clsx('menu-link px-3 py-2')}
						>
							<span className='menu-icon' data-kt-element='icon'>
								<SVG src={toAbsoluteUrl(item.icon)} className='' />
							</span>
							<span className='menu-title'>{item.title}</span>
						</Link>
					</div>

				))}
			</div>

		</>
	)
}
export default React.memo(InspectionMenu);