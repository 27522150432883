import { FC } from 'react'

type Props = {
	items: Array<String>
}

const DarkDotItemList: FC<Props> = ({items}) => {
	return (
		<>
			{items.map((item: String, i) => (
				<li key={i} className="d-flex align-items-center m-2">
					<span className="bullet bullet-dot bg-dark me-5"></span>{item}
				</li>
			))}
		</>
	)
}

export { DarkDotItemList }

	
