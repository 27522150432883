import { FC } from 'react'

type Props = {
	status?: number
}

const InspectionStatusCell: FC<Props> = ({ status }) => {
	return (
		<>
			{status === 1 && <div style={{color: '#469CF0'}}>W trakcie</div>}
			{status === 2 && <div style={{color: '#76BD64'}}>Zakończone</div>}
			{status === 3 && <div style={{color: '#A2A5B5'}}>Usunięty</div>}
		</>
	)
}

export { InspectionStatusCell }
