/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import SVG from 'react-inlinesvg'

import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { ID, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
//import mainClasses from './../../../../main.module.css';
import { Link } from 'react-router-dom'
import { useListView } from './../../core/ListViewProvider'
import { Inspection } from './../../core/_models'

type Props = {
	inspection: Inspection
}

const InspectionActionsCell: FC<Props> = ({ inspection }) => {
	const { setOpenModalConfirmDeleteInspection } = useListView()

	useEffect(() => {
		MenuComponent.reinitialization()
	}, [])

	return (
		<>
			<Link to={`/inspection-form/${inspection.id}`} className={`menu-link me-2`}
				style={{ cursor: 'pointer' }}>
				<SVG src={toAbsoluteUrl("/media/inspection/writeRed.svg")} style={{width: '30px', height: '30px'}} />
			</Link>

			{inspection.status !== 3 && <Link to="#" className={`menu-link`} onClick={() => { setOpenModalConfirmDeleteInspection(inspection) }}
				style={{ cursor: 'pointer' }}>
				<SVG
					src={toAbsoluteUrl('/media/inspection/trashFrame.svg')}
					style={{width: '30px', height: '30px'}}
				/>
			</Link>
			}
		</>
	)
}

export { InspectionActionsCell }
