import React from 'react'

import { QuestionTextAriaField } from './component/QuestionTextAriaField'
import { useQueryResponseLoading } from './QuestionFormProvider'
import mainClasses from './../../../main.module.css'

const Other = () => {
	const isLoading = useQueryResponseLoading()

	return (
		<>
			{!isLoading &&

				<div className='row'>
					<div className='card card-xxl-stretch'>
						{/* begin::Header */}
						<div className='card-header border-0 d-flex text-center w-100 align-content-center justify-content-center'>
							{/* begin::Title */}
							<h3 className={`${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['ifs-23']}`}>Inne</h3>

							{/* end::Title */}

							{/* begin::Toolbar */}
							<div className='card-toolbar'>
								{/* begin::Menu */}
								{/* end::Menu */}
							</div>
							{/* end::Toolbar */}
						</div>

						{/* end::Header */}

						<div className='card-body'>
							<QuestionTextAriaField title="1. Uwagi ogólne" dictId={187} />
						</div>
					</div>
				</div>}
		</>
	)
}

export default React.memo(Other)

