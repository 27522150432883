import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import InspectionPage from './../modules/inspection-management/InspectionPage'
import InspectionQuestionPage from './../modules/inspection-management/inspection-question/InspectionQuestionPage'
import { InspectionQueryProvider } from './../modules/inspection-management/inspection-detail/core/InspectionQueryProvider'

const PrivateAdminRoutes = () => {
	const UsersPage = lazy(() => import('../modules/user-management/UsersPage'))
	const UserDetailPage = lazy(() => import('../modules/user-management/UserDetailPage'))

	const CompanyPage = lazy(() => import('../modules/company-management/CompanyPage'))
	const CompanyDetailPage = lazy(() => import('../modules/company-management/CompanyDetailPage'))

	const InspectionForm = lazy(() => import('../modules/inspection-management/inspection-detail/InspectionForm'))

	return (
		<Routes>
			<Route element={<MasterLayout />}>
				{/* Redirect to Dashboard after success login/registartion */}
				<Route path='auth/*' element={<Navigate to='/inspection-management' />} />

				{/* Lazy Modules */}
				<Route
					path='user-management/*'
					element={
						<SuspensedView>
							<UsersPage />
						</SuspensedView>
					}
				/>
				<Route
					path='user-detail/:id'
					element={
						<SuspensedView>
							<UserDetailPage />
						</SuspensedView>
					}
				/>
				<Route
					path='company-management/*'
					element={
						<SuspensedView>
							<CompanyPage />
						</SuspensedView>
					}
				/>
				<Route
					path='company-detail/:id'
					element={
						<SuspensedView>
							<CompanyDetailPage />
						</SuspensedView>
					}
				/>
				<Route
					path='inspection-management/*'
					element={
						<SuspensedView>
							<InspectionPage />
						</SuspensedView>
					}
				/>
				<Route
					path='/inspection-form/:id'
					element={
						<SuspensedView>
							<InspectionForm />
						</SuspensedView>
					}
				/>
				<Route
					path='/inspection-form/'
					element={
						<SuspensedView>
							<InspectionForm />
						</SuspensedView>
					}
				/>
				<Route
					path='inspection-management/inspection-question/:id/form'
					element={
						<SuspensedView>
							<InspectionQueryProvider>
								<InspectionQuestionPage />
							</InspectionQueryProvider>

						</SuspensedView>
					}
				/>
				{/* Page Not Found */}
				<Route path='*' element={<Navigate to='/error/404' />} />
			</Route>
		</Routes>
	)
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
	const baseColor = getCSSVariableValue('--bs-primary')
	TopBarProgress.config({
		barColors: {
			'0': baseColor,
		},
		barThickness: 1,
		shadowBlur: 5,
	})
	return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateAdminRoutes }
