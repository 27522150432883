/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, createContext } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import {
	isNotEmpty,
	QUERIES,
	WithChildren,
} from './../../../../../_metronic/helpers'
import { getInspectionById } from './../../inspection-list/core/_requests'
import { Inspection, initialInspectionQueryResponse, QueryInspectionResponseContextProps } from './../../inspection-list/core/_models'

function createResponseContext<T>(initialState: QueryInspectionResponseContextProps<T>) {
	return createContext(initialState)
}

const QueryResponseContext = createResponseContext<Inspection>(initialInspectionQueryResponse)

const InspectionQueryProvider: FC<WithChildren> = ({ children }) => {
	let { id } = useParams();
	const enabledQuery: boolean = isNotEmpty(id) 
	
	const {
		isFetching,
		refetch,
		data: response,
	} = useQuery(
		`${QUERIES.INSPECTION_LIST}-${id}`,
		() => {
			return getInspectionById(Number(id))
		},
		{ cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false, enabled: enabledQuery }
	)

	return (
		<QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response}}>
			{children}
		</QueryResponseContext.Provider>
	)
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
	const { response } = useQueryResponse()

	if (!response) {
		return {}
	}

	return response || {}
}

const useQueryResponseLoading = (): boolean => {

	const { isLoading } = useQueryResponse()

	return isLoading
}

export {
	InspectionQueryProvider,
	useQueryResponse,
	useQueryResponseData,
	useQueryResponseLoading
}
