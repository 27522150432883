import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../../../_metronic/helpers'
import {Company, CompanyQueryResponse, ResponseCompanyById} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const COMPANY_URL = `${API_URL}/companies`
const COMPANY_MODIFY_URL = `${API_URL}/company`

const getCompany = (query: string): Promise<CompanyQueryResponse> => {
  return axios
    .get(`${COMPANY_URL}?${query}`)
    .then((d: AxiosResponse<CompanyQueryResponse>) => d.data)
}

const getCompanyById = (id: ID): Promise<Company | undefined> => {
  return axios
    .get(`${COMPANY_MODIFY_URL}/${id}`)
    .then((response: AxiosResponse<ResponseCompanyById<Company>>) => response.data)
}

const createCompany = (company: Company): Promise<Company | undefined> => {
  return axios
    .post(COMPANY_MODIFY_URL, company)
    .then((response: AxiosResponse<ResponseCompanyById<Company>>) => response.data)
}

const updateCompany = (company: Company): Promise<Company | undefined> => {
  return axios
    .put(`${COMPANY_MODIFY_URL}/${company.id}`, company)
    .then((response: AxiosResponse<ResponseCompanyById<Company>>) => response.data)
}

export {getCompany, getCompanyById, createCompany, updateCompany}
