import React from 'react'

import { useQueryResponseLoading, useResponseDataByDictId } from './QuestionFormProvider'
import { QuestionYesNoField } from './component/QuestionYesNoField'

import mainClasses from './../../../main.module.css'

const MainInformation = () => {
	const isLoading = useQueryResponseLoading()
	
	return (
		<>
			{ !isLoading &&
				<div className='row'>
					<div className='card card-xxl-stretch mb-1'>
						{/* begin::Header */}
						<div className='card-header border-0 d-flex text-center w-100 align-content-center justify-content-center'>
							{/* begin::Title */}
							<h3 className={`${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['ifs-23']}`}>1. Informacje ogólne</h3>
							{/* end::Title */}
							{/* begin::Toolbar */}
							<div className='card-toolbar'>
								{/* begin::Menu */}
								{/* end::Menu */}
							</div>
							{/* end::Toolbar */}
						</div>
						{/* end::Header */}

						<div className='card-body'>
							<div className='row'>
								<div className='col-xl-6'>
									<QuestionYesNoField dictId={51} title="1. Czy nr VIN pojazdu różni się od podanego w dokumentach?" />
								</div>
								<div className="d-xl-none separator separator-dashed my-5"></div>
								<div className='col-xl-6'>
									<QuestionYesNoField dictId={52} title="2. Czy pole z numerem VIN jest uszkodzone?" />
								</div>
							</div>
						</div>
					</div>
				</div>}
		</>
	)
}

export default React.memo(MainInformation)