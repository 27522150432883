const QUERIES = {
  USERS_LIST: 'users-list',
  INSPECTION_LIST: 'inspection-list',
  BRANDS_LIST: 'brands-list',
  COUNTRY_LIST: 'country-list',
  BRAND_MODEL_LIST: 'brand-model-list',
  COMPANY_LIST: 'company-list',
  CATEGORIES_LIST: 'categories-list',
  DAMAGE_LIST: 'damage_list', 
  DOCUMENT_LIST: 'document_list'
}

export {QUERIES}
