import React from 'react'

import { QuestionSimpleField } from './component/QuestionSimpleField'
import { QuestionInformationLabel } from './component/QuestionInformationLabel'
import { useQueryResponseLoading } from './QuestionFormProvider'
import mainClasses from './../../../main.module.css'

const CheckingOperationSystems = () => {
	const isLoading = useQueryResponseLoading()

	return (
		<>
			{ !isLoading &&
				<div className='row'>
					<div className='card card-xxl-stretch'>
						<div className='card-header border-0 d-flex text-center w-100 align-content-center justify-content-center'>
							<h3 className={`${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['ifs-23']}`}>11. Kontrola działania systemów</h3>

							<div className='card-toolbar'>
							</div>
						</div>
						<div className='card-body'>
							<QuestionInformationLabel />
							<QuestionSimpleField title="1. Czy świecą się kontrolki ostrzegawcze po uruchomieniu silnika" dictId={154} />
							<QuestionSimpleField title="2. Kontrola sprzęgła" dictId={155} />
							<QuestionSimpleField title="3. Działanie skrzyni biegów" dictId={156} />
							<QuestionSimpleField title="4. Działanie hamulców" dictId={157} />
							<QuestionSimpleField title="5. Działanie ABS" dictId={158} />
							<QuestionSimpleField title="6. Działanie ESP" dictId={159} />
							<QuestionSimpleField title="7. Działanie hamulca ręcznego" dictId={160} />
							<QuestionSimpleField title="8. Hałaśliwość łożysk kół" dictId={161} />
							<QuestionSimpleField title="9. Hałaśliwość układu napędowego" dictId={162} />
							<QuestionSimpleField title="10. Wibracje wewnętrzne" dictId={163} />
							<QuestionSimpleField title="11. Działanie obrotomierza" dictId={164} />
							<QuestionSimpleField title="12. Działanie licznika przebiegu" dictId={165} />
							<QuestionSimpleField title="13. Działanie przyrządów" dictId={166} />
							<QuestionSimpleField title="14. Działanie klimatyzacji" dictId={167} />
							<QuestionSimpleField title="15. Działanie ogrzewania i sprawności systemu nawiewu" dictId={168} />
							<QuestionSimpleField title="16. Działanie ogrzewania szyb" dictId={169} />
							<QuestionSimpleField title="17. Działanie podgrzewania/wentylacji foteli" dictId={170} />
							<QuestionSimpleField title="18. Działanie ręcznej/elektrycznej regulacji foteli" dictId={171} />
							<QuestionSimpleField title="19. Działanie spryskiwacza szyb przód/tył" dictId={172} />
							<QuestionSimpleField title="20. Działanie sygnału dźwiękowego" dictId={173} />
							<QuestionSimpleField title="21. Działanie tempomatu" dictId={174} />
							<QuestionSimpleField title="22. Działanie czujników parkowania i kamer zewnętrznych" dictId={175} />
							<QuestionSimpleField title="23. Działanie systemu audio-video" dictId={176} />
							<QuestionSimpleField title="24. Działanie systemu nawigacji satelitarnej oraz oprogramowania nawigacyjnego" dictId={177} />

						</div>
					</div>
				</div>}
		</>
	)
}

export default React.memo(CheckingOperationSystems)

