//import {useQuery} from 'react-query'
import { FC, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getQuestion } from './core/_requests'
import { Question } from './core/_models'

import {
	createResponseContext,
	WithChildren,
	QUERIES
} from '../../../../../_metronic/helpers'

const initialQueryResponse = { refetch: () => { }, isLoading: false, query: '' }

const QueryResponseContext = createResponseContext<Question>(initialQueryResponse)
const QuestionFormProvider: FC<WithChildren> = ({ children }) => {
	const { id } = useParams();
	const inspectionId = String(id);
	const {
		isLoading,
		refetch,
		data: response,
	} = useQuery(
		`${QUERIES.USERS_LIST}-user-${inspectionId}`,
		() => {
			return getQuestion(inspectionId)
		},
		{ cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }

	)

	return (
		<QueryResponseContext.Provider value={{ isLoading, refetch, response, query: '' }}>
			{children}
		</QueryResponseContext.Provider>
	)

}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
	const { response } = useQueryResponse()
	if (!response) {
		return []
	}

	return response?.data || []
}

const useQueryResponseLoading = (): boolean => {
	const { isLoading } = useQueryResponse()
	return isLoading
}

const useResponseDataByDictId = (dictId: number) => {
	const response = useQueryResponseData();

	return response.find(obj => {
		return obj.questionDictId === dictId;
	});
}

export {
	QuestionFormProvider,
	useQueryResponse,
	useQueryResponseData,
	useQueryResponseLoading,
	useResponseDataByDictId
}