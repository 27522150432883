import React, { useEffect, useState, useMemo } from "react";
import { Link } from 'react-router-dom'

import { KTSVG } from '../../../../../../_metronic/helpers'

import { useQueryResponsePagination } from '../../core/QueryResponseProvider'
import { useQueryRequest } from '../../core/QueryRequestProvider'

const Pagination = () => {
	const pagination = useQueryResponsePagination()

	const [pages, setPages] = useState([1]);
	const [currentPage, setCurrentPage] = useState(pagination.currentPage)
	const lastPage = Math.ceil(pagination.total / pagination.limit);
	const { state, updateState } = useQueryRequest()

	const updatedQuery = useMemo(() => state.query, [state.query])

	useEffect(() => {
		setCurrentPage(1);
	}, [updatedQuery])

	const handlePageClick = (event: any, pageNumber: number) => {
		event.preventDefault();
		pageNumber = pageNumber < 1 ? 1 : pageNumber;
		pageNumber = pageNumber > lastPage ? lastPage : pageNumber;
		setCurrentPage(pageNumber);
		pagination.currentPage = pageNumber;
		updateState({ offset: pagination.limit * (pageNumber - 1) })
	};

	useEffect(() => {
		const initialPage = currentPage - 5 >= 1 ? currentPage - 5 : 1;
		const lastIndex = currentPage + 5 <= lastPage ? currentPage + 5 : lastPage

		const tempPages = [];
		for (let i = initialPage; i <= lastIndex; i++) {
			tempPages.push(i);
		}

		setPages(tempPages);
	}, [currentPage, pagination.total, pagination.offset]);

	if (!pagination.limit || !pagination.total) {
		return <></>;
	}

	return (

		<div className="row">
			<div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
				<div
					className="dataTables_paginate paging_simple_numbers"
					id="kt_profile_overview_table_paginate"
				>
					<ul className="pagination">
						<li
							className={(1 == currentPage ? " disabled" : "")}
						>
							<Link to={`#`} className={`btn btn-icon btn-sm mr-2 my-1`} onClick={(e) => handlePageClick(e, currentPage - 1)}>
								<KTSVG path={"/media/inspection/paginationArrLeft.svg"} className="svg-icon-2hx" />
							</Link>
						</li>
						{pages.map((page) => (
							<li key={page}>
								<Link to={`#`} className={`btn btn-icon btn-sm border-0 m-1`} onClick={(e) => handlePageClick(e, page)}
									style={{ background: page == currentPage ? 'rgba(217, 9, 9, 0.1)' : '#E6E8EA' }}>
									{page}
								</Link>
							</li>
						))}
						<li>
							<Link to={`#`} className={`btn btn-icon btn-sm mr-2 my-1`} onClick={(e) => handlePageClick(e, currentPage + 1)}
								style={{ cursor: 'pointer' }}>
								<KTSVG path={'/media/inspection/paginationArrRight.svg'} className="svg-icon-2hx" />
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default React.memo(Pagination);

