import { FC } from 'react'
import { createQuestion } from './../core/_requests'
import { useParams } from 'react-router-dom'
import { useResponseDataByDictId } from './../QuestionFormProvider'
import mainClasses from './../../../../main.module.css'
import { useSubmitValidationContext } from './../core/InspectionSubmitValidation'

type Props = {
	dictId: number
	value: string
	name: string
}
const QuestionInputRadioElement: FC<Props> = ({ dictId, value, name }) => {
	const { id } = useParams();
	const fieldData = useResponseDataByDictId(dictId)
	
	const { errorsQuestion, setErrorsQuestion } = useSubmitValidationContext()

	const onChange = (e: React.FormEvent<HTMLInputElement>) => {
		const inputValue = e.currentTarget.value;

		setErrorsQuestion(errorsQuestion.filter((errorQuestion) => errorQuestion !== dictId))

		createQuestion(String(id).trim(), {
			id: fieldData?.id,
			value: inputValue,
			comment: ''
		});
	}

	return (
		<>
			{ fieldData &&
				<>
					<input type="radio" className="btn-check" name={`${dictId}`} id={`${dictId}${value}`} onChange={onChange} value={value} defaultChecked={fieldData?.value === value} />
					<label style={{height: '38px'}} className={`btn btn-sm btn-light-danger m-2 ${mainClasses['inspection-text']} ${mainClasses['ifs-14']} ${mainClasses['itext-600']} ${mainClasses['question-bool-btn']}`} htmlFor={`${dictId}${value}`} >{name}</label>
				</>
			}
		</>
	)
}

export { QuestionInputRadioElement }
