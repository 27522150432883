/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, createContext, useState } from 'react'
import { useQuery } from 'react-query'
import {
	ID,
	QUERIES,
	WithChildren,
} from './../../../../../_metronic/helpers'
import { getListBrands } from './../../inspection-list/core/_requests'
import { SelectModel, QueryResponseContextProps, initialQueryResponse } from './../../inspection-list/core/_models'

function createResponseContext<T>(initialState: QueryResponseContextProps<T>) {
	return createContext(initialState)
}

const QueryResponseContext = createResponseContext<SelectModel>(initialQueryResponse)

const BrandListSelectProvider: FC<WithChildren> = ({ children }) => {
	
	const [brandId, setBrandId] = useState<ID>(initialQueryResponse.selectedOptionId)

	const {
		refetch,
		isFetching: brandsIsFetching,
		data: response,
	} = useQuery(
		`${QUERIES.BRANDS_LIST}`,
		() => {
			return getListBrands();
		},
		{ cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
	)

	return (
		<QueryResponseContext.Provider value={{selectedOptionId: brandId, setSelectedOptionId: setBrandId,  refetch, response, isSelectModelLoading: brandsIsFetching }}>
			{children}
		</QueryResponseContext.Provider>
	)
}

const useQueryBrandResponse = () => useContext(QueryResponseContext)

const useBrandListSelectData  = () => {
	const { response } = useQueryBrandResponse()

	if (!response) {
		return []
	}

	return response || []
}

const useQueryBrandsResponseLoading = (): boolean => {
	const { isSelectModelLoading } = useQueryBrandResponse()
	return (isSelectModelLoading)
}

export {
	BrandListSelectProvider,
	useQueryBrandResponse,
	useQueryBrandsResponseLoading,
	useBrandListSelectData,
}
