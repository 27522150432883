import { FC, useEffect, useState, useRef } from 'react'
//import clsx from 'clsx'
import { createQuestion } from './../core/_requests'
import { useParams } from 'react-router-dom'
import { useResponseDataByDictId } from './../QuestionFormProvider'
import mainClasses from './../../../../main.module.css'
import { useSubmitValidationContext } from './../core/InspectionSubmitValidation'
import { useDebounce } from '../../../../../../_metronic/helpers'

type Props = {
	title?: string,
	dictId: number
	placeholder?: string
}

const QuestionTextAriaField: FC<Props> = ({ title, dictId, placeholder }) => {
	const { id } = useParams();
	const fieldData = useResponseDataByDictId(dictId)
	const [value, setValue] = useState<string>(fieldData?.value)
	const { errorsQuestion, setErrorsQuestion } = useSubmitValidationContext()

	const debouncedValueTerm = useDebounce(value, 550)
	const isInitialMount = useRef(true);

	useEffect(
		() => {
			if (debouncedValueTerm !== undefined && value !== undefined) {
				if (isInitialMount.current) {
					isInitialMount.current = false;
				} else {
					createQuestion(String(id).trim(), {
						id: fieldData?.id,
						value: value,
						comment: ''
					})
				}
			}
		},
		[debouncedValueTerm]
	)

	return (
		<>
			<div className='row d-flex  my-2 align-content-start justify-content-start'>
				<label className={`${mainClasses['inspection-text']} ${mainClasses['itext-600']} ${mainClasses['inspection-dark']} ${mainClasses['ifs-14']}`} htmlFor={`${dictId}`}>{title}</label>

				<textarea onChange={(e) => {
					setValue(e.target.value);
					setErrorsQuestion(errorsQuestion.filter((errorQuestion) => errorQuestion !== dictId))
				}} value={value} className={`form-control ${mainClasses['textaria-field']}`} placeholder={placeholder || 'Odpowiedź'}
					name={`${dictId}`}
					autoComplete='off'
                    maxLength={20480}
                    />
			</div>
		</>
	)
}

export { QuestionTextAriaField }
