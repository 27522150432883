import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import mainClasses from './../../main.module.css';
import { resetPassword } from './../core/_requests'

const initialValues = {
	email: '',
}

const forgotPasswordSchema = Yup.object().shape({
	email: Yup.string()
		.email('E-mail jest wymagany')
		.min(3, 'Minimum 3 symbole')
		.max(50, 'Maksymalnie 50 symboli')
		.required('E-mail jest wymagany'),
})

export function ForgotPassword() {
	const [loading, setLoading] = useState(false)
	const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
	const formik = useFormik({
		initialValues,
		validationSchema: forgotPasswordSchema,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			setLoading(true)
			setHasErrors(undefined)
			setTimeout(() => {
				resetPassword(values.email)
					.then(({ data: { result } }) => {
						setHasErrors(false)
						setLoading(false)
					})
					.catch(() => {
						setHasErrors(true)
						setLoading(false)
						setSubmitting(false)
						setStatus('Dane logowania są nieprawidłowe')
					})
			}, 1000)
		},
	})

	return (
		<form
			className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
			noValidate
			id='kt_login_password_reset_form'
			onSubmit={formik.handleSubmit}
		>
			<div className='text-center mb-10'>
				{/* begin::Title */}
				<h1 className={`${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['inspection-text-27']}`}>Odzyskaj konto</h1>
				{/* end::Title */}

				{/* begin::Link */}
				<span className={`${mainClasses['itext-600']} ${mainClasses['inspection-text']} ${mainClasses['inspection-muted-text']} ${mainClasses['inspection-text-14']}`}>
					Wyślemy na ten email link resetujący hasło
        </span>
				{/* end::Link */}
			</div>

			{/* begin::Title */}
			{hasErrors === true && (
				<div className='mb-lg-10 alert alert-danger'>
					<div className={`${mainClasses['itext-600']} ${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['inspection-text-14']}`}>
						Przepraszamy, wygląda na to, że wykryto jakieś błędy, spróbuj ponownie
          			</div>
				</div>
			)}

			{hasErrors === false && (
				<div className='mb-10 bg-light-info p-8 rounded'>
					<div className={`${mainClasses['itext-600']} ${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['inspection-text-14']}`}>Wysłano zresetowanie hasła. Proszę sprawdzić email</div>
				</div>
			)}
			{/* end::Title */}

			{/* begin::Form group */}
			<div className='fv-row mb-8'>
				<input
					type='email'
					placeholder='Aktualny email'
					autoComplete='off'
					{...formik.getFieldProps('email')}
					className={clsx(
						`form-control bg-transparent ${mainClasses['inspection-field']}`,
						{ 'is-invalid': formik.touched.email && formik.errors.email },
						{
							'is-valid': formik.touched.email && !formik.errors.email,
						}
					)}
				/>
				{formik.touched.email && formik.errors.email && (
					<div className='fv-plugins-message-container'>
						<div className={`fv-help-block ${mainClasses['itext-600']} ${mainClasses['inspection-text']} ${mainClasses['inspection-text-14']}`}>
							<span role='alert'>{formik.errors.email}</span>
						</div>
					</div>
				)}
			</div>

			<div className='d-grid mb-10'>
				<button
					type='submit'
					id='kt_password_reset_submit'
					className={`btn btn-danger`}
					disabled={formik.isSubmitting || !formik.isValid}
				>
					{!loading && <span className={`${mainClasses['inspection-text']} ${mainClasses['itext-600']} ${mainClasses['inspection-text-14']}`}>Odzyskaj konto</span>}
					{loading && (
						<span className='indicator-progress' style={{ display: 'block' }}>
							Proszę czekać...
							<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
						</span>
					)}
				</button>
			</div>
		</form>
	)
}
