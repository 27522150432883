import { useRef, forwardRef } from 'react'
import { FC } from 'react'
import { Link } from "react-scroll";
import mainClasses from './../../../../main.module.css'

type Props = {
	stepNum?: number
	title?: string
	keyI: string
	scrollCategoryMenu?: boolean
	scrollParent?: (leftSize: number) => void
}

const MenuItem: FC<Props> = ({ keyI, title, stepNum, scrollCategoryMenu = false, scrollParent }) => {
	const elementDiv = useRef<HTMLDivElement | null>(null);

	return (
		<div ref={elementDiv} key={keyI}>
			{/* begin::Wrapper*/}
			<Link
				activeClass="current"
				data-kt-stepper-element='nav'
				className='stepper-item btn btn-link btn-dashed'
				to={`element_name_${stepNum}`}
				smooth={true}
				duration={2000}
				spy={true}
				offset={!scrollCategoryMenu ? -60 : -150}
				onSetActive={(to, element) => {
					if (scrollParent) {
						scrollParent(elementDiv?.current?.offsetLeft || 0);
					}
				}
				}
			>
				<div className='stepper-wrapper' >
					{/* begin::Icon*/}
					<div className='stepper-icon w-40px h-40px'>
						<span className='stepper-number'>{stepNum || '-'}</span>
					</div>

					{/* begin::Label*/}
					<div className='stepper-label'>
						<h3 className={`stepper-title text-start ${mainClasses['inspection-text']} ${mainClasses['inspection-dark']} ${mainClasses['inspection-text-14']}`}>{title}</h3>
					</div>

					{/* end::Label*/}
				</div>
			</Link>
			{/* begin::Line*/}
			{<div className='stepper-line h-15px'></div>}
			{/* end::Line*/}
		</div>
	)
}



export default MenuItem