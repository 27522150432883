import { WithChildren } from '../../../../../_metronic/helpers'

import { BrandListSelectProvider } from './../core/BrandListSelectProvider'
import { ModelListSelectProvider } from './../core/ModelListSelectProvider'
import { ModelUsersListSelectProvider } from './../core/ModelUsersListSelectProvider'

const InspectionEditFormBrandsModelFields: React.FC<WithChildren> = ({ children }) => {
	return (
		<>
			<ModelUsersListSelectProvider>
				<BrandListSelectProvider>
					<ModelListSelectProvider>
						{children}
					</ModelListSelectProvider>
				</BrandListSelectProvider>
			</ModelUsersListSelectProvider>
		</>
	)
}

export { InspectionEditFormBrandsModelFields }

