import React from 'react'

import { QuestionSimpleField } from './component/QuestionSimpleField'
import { QuestionInformationLabel } from './component/QuestionInformationLabel'
import { useQueryResponseLoading } from './QuestionFormProvider'
import mainClasses from './../../../main.module.css'

const HybridSystemDiagnostics = () => {
	const isLoading = useQueryResponseLoading()

	return (
		<>
			{ !isLoading &&
				<div className='row'>
					<div className='card card-xxl-stretch'>
						{/* begin::Header */}
						<div className='card-header border-0 d-flex text-center w-100 align-content-center justify-content-center'>
							{/* begin::Title */}
							<h3 className={`${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['ifs-23']}`}>12. Układ hybrydowy</h3>
							{/* end::Title */}

							{/* begin::Toolbar */}
							<div className='card-toolbar'>
								{/* begin::Menu */}
								{/* end::Menu */}
							</div>
							{/* end::Toolbar */}
						</div>
						{/* end::Header */}

						<div className='card-body'>
							<QuestionInformationLabel />
							<QuestionSimpleField title="1. Kontrola przewodów wysokiego napięcia: stan izolacji i złączy" dictId={181} />
							<QuestionSimpleField title="2. Stan pokrywy akumulatora HV" dictId={182} />
							<QuestionSimpleField title="3. Stan pokrywy falownika" dictId={183} />
							<QuestionSimpleField title="4. Test akumulatora układu hybrydowego HHC" dictId={184} />

						</div>
					</div>
				</div>}
		</>
	)
}

export default React.memo(HybridSystemDiagnostics)

