import mainClasses from './../../../../main.module.css'


const QuestionInformationLabel = () => {
	return (
		<div className='row d-flex align-content-start justify-content-start'>

			<span className={`col-xl-4 my-2 ${mainClasses['inspection-text']} ${mainClasses['itext-600']} ${mainClasses['inspection-muted-text']} ${mainClasses['ifs-14']}`}>P – PRAWIDŁOWY</span>
			<span className={`col-xl-4 my-2 ${mainClasses['inspection-text']} ${mainClasses['itext-600']} ${mainClasses['inspection-muted-text']} ${mainClasses['ifs-14']}`}>N – NIEPRAWIDŁOWY</span>
			<span className={`col-xl-4 my-2 ${mainClasses['inspection-text']} ${mainClasses['itext-600']} ${mainClasses['inspection-muted-text']} ${mainClasses['ifs-14']}`}>N/D – NIE DOTYCZY</span>
		</div>
	)
}

export { QuestionInformationLabel }
