import React from 'react'

//import { KTSVG } from '../../../../../../_metronic/helpers'
//import classes from './Form.module.css';
import { QuestionSimpleField } from './component/QuestionSimpleField'
import { QuestionInformationLabel } from './component/QuestionInformationLabel'
import { useQueryResponseLoading, useResponseDataByDictId } from './QuestionFormProvider'
import mainClasses from './../../../main.module.css'

const Appearance = () => {
	const isLoading = useQueryResponseLoading()

	return (
		<>
			{ !isLoading &&
				<div className='row'>
					<div className='card card-xxl-stretch'>
						<div className='card-header border-0 d-flex text-center w-100 align-content-center justify-content-center'>
							<h3 className={`${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['ifs-23']}`}>5. Wygląd zewnętrzny</h3>

							<div className='card-toolbar'>
							</div>
						</div>

						<div className='card-body'>
							<QuestionInformationLabel />
							<QuestionSimpleField title="1. Stan piór wycieraczek przód/tył" dictId={78} />
							<QuestionSimpleField title="2. Stan szyby przedniej" dictId={79} />
							<QuestionSimpleField title="3. Stan szyby tylnej" dictId={80} />
							<QuestionSimpleField title="4. Stan szyb bocznych" dictId={81} />
							<QuestionSimpleField title="5. Sprawdzenie zderzaków (mocowanie, uszkodzenia, otarcia)" dictId={82} />
							<QuestionSimpleField title="6. Stan pokrywy silnika" dictId={83} />
							<QuestionSimpleField title="7. Stan klapy bagażnika" dictId={84} />
							<QuestionSimpleField title="8. Stan reflektorów przednich (uszkodzenia, zaparowanie, zmatowienia)" dictId={85} />
							<QuestionSimpleField title="9. Stan świateł tylnych i bocznych (uszkodzenia, zaparowanie, zmatowienia)" dictId={86} />
							<QuestionSimpleField title="10. Stan i działanie anteny" dictId={87} />
							<QuestionSimpleField title="11. Działanie zamków, w tym blokady zamka drzwi (rodzicielska)" dictId={88} />
							<QuestionSimpleField title="12. Działanie alarmu" dictId={89} />

						</div>
					</div>
				</div>
			}
		</>
	)
}

export default React.memo(Appearance)

