import { FC } from 'react'
import { usePageData } from '../../../core/PageData'
import mainClasses from './../../../../../app/modules/main.module.css'

const DefaultTitle: FC = () => {
	const { pageTitle, pageDescription } = usePageData()

	return (<>
		<div className='page-title d-flex justify-content-center justify-content-md-start flex-column'  style={{ width: '65%' }}>
			{/* begin::Title */}
			{pageTitle && (
				<>
					<div className='d-flex d-lg-none flex-column'>
						<span className={`text-center ${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['ifs-16']}`}>{pageTitle}</span>
						{pageDescription && (
							<>
								<span className={`text-center ${mainClasses['inspection-text']} ${mainClasses['itext-500']} ${mainClasses['inspection-dark-blue']} ${mainClasses['ifs-16']}`}>{pageDescription}</span>
							</>
						)}
					</div>

				</>
			)}
			{/* end::Title */}

		</div>
	</>
	)
}

export { DefaultTitle }
