/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../helpers'
import { useLayout } from '../../core'
import HeaderToolbar from './HeaderToolbar'
import classesStyle from './HeaderWrapper.module.css';

export function HeaderWrapper() {
	const { config, classes, attributes } = useLayout()
	const { aside } = config
 
	return (
		<div
			id='kt_header'
			className={clsx('header', classes.header.join(' '), 'd-lg-none position-sticky')}
			style={{ zIndex: 20, top: '0px', height: '80px', backgroundColor: "white" }}
			{...attributes.headerMenu}
		>
			{/* begin::Brand */}
			<div className='header-brand d-none d-lg-flex'>
				{/* begin::Logo */}
				<Link to='/inspection-management/inspection'>
					<KTSVG
						path='/media/svg/brand-logos/otomoto.svg'
						svgClassName={`mh-150px ${classesStyle['logo']}`}
					/>
				</Link>
				{/* end::Logo */}

				{aside.minimize && (
					<div
						id='kt_aside_toggle'
						className='btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize'
						data-kt-toggle='true'
						data-kt-toggle-state='active'
						data-kt-toggle-target='body'
						data-kt-toggle-name='aside-minimize'
					>
						<KTSVG
							path='/media/inspection/arrLeft.svg'
							className='svg-icon-1 me-n1 minimize-default' svgClassName={`${classesStyle['toggleButton']}`}
						/>
						<KTSVG
							path='/media/inspection/arrLeft.svg'
							className='rotate-180 svg-icon-1 minimize-active'
						/>
					</div>
				)}
			</div>
			{/* end::Brand */}
			<HeaderToolbar />
		</div>
	)
}



