import React, { FC, useRef } from 'react'
import { Link, useParams } from "react-router-dom";
import { QuestionFormProvider } from './form/QuestionFormProvider'
import MainInformation from './form/MainInformation' // 1. Informacje ogólne
import ControlPaintCoating from './form/ControlPaintCoating' // 2. Kontrola powłoki lakierniczej w mikronach
import TireSizeCondition from './form/TireSizeCondition' // 3. Rozmiar i stan opon
import BrakeBlocksBrakeDisc from './form/BrakeBlocksBrakeDisc' // 4. Klocki i tarcze
import Appearance from './form/Appearance' // 5. Wygląd zewnętrzny
import Lighting from './form/Lighting' //6. Oświetlenie
import EngineCompartment from './form/EngineCompartment' //7. Komora silnika
import VehicleInterior from './form/VehicleInterior' //8. Wnętrze pojazdu
import VehicleTrunk from './form/VehicleTrunk' //9. Bagażnik
import SteeringSystemChassis from './form/SteeringSystemChassis' //10. Układ kierowniczy i podwozie
import CheckingOperationSystems from './form/CheckingOperationSystems' //11. Kontrola pojazdu
import HybridSystemDiagnostics from './form/HybridSystemDiagnostics' //12. Diagnostyka układu hybrydowego
import Other from './form/Other' // Inne
import { useViewport } from './../../UI/ViewportContext'

import { VerticalMenu } from './stepper/components/VerticalMenu'
import HorizontalMenu from './stepper/components/HorizontalMenu'

import { Element } from "react-scroll";
import { KTSVG } from '../../../../_metronic/helpers'
import mainClasses from './../../main.module.css'
import { InspectionSubmitValidation } from './form/core/InspectionSubmitValidation'
import ConfirmInspection from './form/ConfirmInspection'
import { PageTitle, PageToolMenu } from './../../../../_metronic/layout/core'
import { useQueryResponseData, useQueryResponseLoading } from './../inspection-detail/core/InspectionQueryProvider'

const InspectionQuestionPage: FC = () => {
	const { id } = useParams();
	const { width } = useViewport()

	const menuItems = [
		{ title: 'Inspekcje', icon: '/media/inspection/inspectionMenuFile.svg', href: `/inspection-form/${id}` },
	]
	const isLoading = useQueryResponseLoading()
	const inspection = useQueryResponseData()

	return (
		<>
			{ !isLoading && <>
				<PageTitle description={`${inspection.registrationNumber} / ${inspection.vin}`}>{`${inspection.brandName} ${inspection.modelName}`} </PageTitle>
				<PageToolMenu menuItems={menuItems} />

				<div className='row justify-content-between m-4'>
					<div className="col d-none d-lg-flex">
						<h1 className='d-flex flex-column'>
							<span className={`text-start ${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['ifs-27']}`}>{inspection.brandName} {inspection.modelName}</span>
							{inspection.vin && (
								<>
									<span className={`text-start mt-3 ${mainClasses['inspection-text']} ${mainClasses['itext-500']} ${mainClasses['inspection-dark-blue']} ${mainClasses['ifs-16']}`}>{inspection.registrationNumber + '/' + inspection.vin}</span>
								</>
							)}
						</h1>
					</div>
					<div className="col-12 col-lg-5  d-flex justify-content-center justify-content-md-end me-5" style={{ 'height': '44px' }}>
						<Link to={`/inspection-form/${id}`} className={`btn btn-light-danger w-100 w-md-200px`}>
							<KTSVG path="/media/inspection/arrowLeftRed.svg" className="svg-icon-1" />
							<span className={`p-1 ${mainClasses['itext-600']} ${mainClasses['inspection-text']} ${mainClasses['ifs-14']}`}>Poprzedni krok</span>
						</Link>
					</div>

				</div>
				<InspectionSubmitValidation>
					{width > 1200 && <VerticalMenu>
						<QuestionFormProvider>
							<div className="container" id="vertical_menu_element">
								<Element key={"display" + 1} name="element_name_1" id="element_id_1" className="mt-8">
									<MainInformation />
								</Element>

								<Element key={"display" + 2} name="element_name_2" id="element_id_2" className="mt-8">
									<ControlPaintCoating />
								</Element>
								<Element key={"display" + 3} name="element_name_3" id="element_id_3" className="mt-8">
									<TireSizeCondition />
								</Element>
								<Element key={"display" + 4} name="element_name_4" id="element_id_4" className="mt-8">
									<BrakeBlocksBrakeDisc />
								</Element>
								<Element key={"display" + 5} name="element_name_5" id="element_id_5" className="mt-8">
									<Appearance />
								</Element>
								<Element key={"display" + 6} name="element_name_6" id="element_id_6" className="mt-8">
									<Lighting />
								</Element>
								<Element key={"display" + 7} name="element_name_7" id="element_id_7" className="mt-8">
									<EngineCompartment />
								</Element>
								<Element key={"display" + 8} name="element_name_8" id="element_id_8" className="mt-8">
									<VehicleInterior />
								</Element>
								<Element key={"display" + 9} name="element_name_9" id="element_id_9" className="mt-8">
									<VehicleTrunk />
								</Element>
								<Element key={"display" + 10} name="element_name_10" id="element_id_10" className="mt-8">
									<SteeringSystemChassis />
								</Element>
								<Element key={"display" + 11} name="element_name_11" id="element_id_11" className="mt-8">
									<CheckingOperationSystems />
								</Element>
								<Element key={"display" + 12} name="element_name_12" id="element_id_12" className="mt-8">
									<HybridSystemDiagnostics />
								</Element>
								<Element key={"display" + 0} name="element_name_0" id="element_id_0" className="mt-8 section-other">
									<Other />
								</Element>
							</div>
						</QuestionFormProvider>
					</VerticalMenu>}
					{width <= 1200 && <HorizontalMenu>
						<div className="container" >

							<QuestionFormProvider>
								<section key={"display" + 1} data-name={1} id="element_name_1" className="mt-8">
									<MainInformation />
								</section>
								<section key={"display" + 2} data-name={2} id="element_name_2" className="mt-8">
									<ControlPaintCoating />
								</section>

								<section key={"display" + 3} data-name={3} id="element_name_3" className="mt-8">
									<TireSizeCondition />
								</section>

								<section key={"display" + 4} data-name={4} id="element_name_4" className="mt-8">
									<BrakeBlocksBrakeDisc />
								</section>

								<section key={"display" + 5} data-name={5} id="element_name_5" className="mt-8">
									<Appearance />
								</section>

								<section key={"display" + 6} data-name={6} id="element_name_6" className="mt-8">
									<Lighting />
								</section>

								<section key={"display" + 7} data-name={7} id="element_name_7" className="mt-8">
									<EngineCompartment />
								</section>

								<section key={"display" + 8} data-name={8} id="element_name_8" className="mt-8">
									<VehicleInterior />
								</section>

								<section key={"display" + 9} data-name={9} id="element_name_9" className="mt-8">
									<VehicleTrunk />
								</section>

								<section key={"display" + 10} data-name={10} id="element_name_10" className="mt-8">
									<SteeringSystemChassis />
								</section>

								<section key={"display" + 11} data-name={11} id="element_name_11" className="mt-8">
									<CheckingOperationSystems />
								</section>

								<section key={"display" + 12} data-name={12} id="element_name_12" className="mt-8">
									<HybridSystemDiagnostics />
								</section>
								<section key={"display" + 0} data-name={0} id="element_name_0" className="mt-8 section-other">
									<Other />
								</section>
							</QuestionFormProvider>
						</div>
					</HorizontalMenu>}
					<ConfirmInspection />
				</InspectionSubmitValidation>
				<div style={{ height: '40vh' }}></div>
			</>}
		</>
	)
}

export default InspectionQuestionPage

