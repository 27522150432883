import { FC } from 'react'
import {
	WithChildren,
} from '../../../../../../_metronic/helpers'
import MenuItem from './MenuItem'

const categories = [
	{ stepNum: 1, name: "Informacje ogólne" },
	{ stepNum: 2, name: "Powłoka lakiernicza" },
	{ stepNum: 3, name: "Rozmiar i stan opon" },
	{ stepNum: 4, name: "Klocki i tarcze" },
	{ stepNum: 5, name: "Wygląd zewnętrzny" },
	{ stepNum: 6, name: "Oświetlenie" },
	{ stepNum: 7, name: "Komora silnika" },
	{ stepNum: 8, name: "Wnętrze pojazdu" },
	{ stepNum: 9, name: "Bagażnik" },
	{ stepNum: 10, name: "Układ kierowniczy i podwozie" },
	{ stepNum: 11, name: "Działanie systemów" },
	{ stepNum: 12, name: "Układ hybrydowy" },
	{ stepNum: 0, name: "Inne" },
]

const VerticalMenu: FC<WithChildren> = ({ children }) => {

	return (
		<>
			<div className="row">
				<div className="col-md-3">
					<div
						className='stepper stepper-pills stepper-column d-flex flex-column subaside'
					>
						{/* begin::Aside*/}
						<div className='card d-flex justify-content-start w-350px w-100 me-2'>
							<div className='card'>

								{/* begin::Wrapper*/}
								<div className='card-body px-1 px-xxl-4 py-1'>
									{/* begin::Nav*/}
									<div className='stepper-nav'>
										{categories.map((category, i) => (<MenuItem key={('display' + i)} keyI={('display' + i)} title={category.name} stepNum={category.stepNum} />))}
									</div>
									{/* end::Nav*/}
								</div>
								{/* end::Wrapper*/}
							</div>
						</div>
						{/* begin::Aside*/}
					</div>
				</div>

				<div className='col'>
					{children}
				</div>
			</div>

		</>
	)
}

export { VerticalMenu }
//d-flex flex-row-fluid flex-center bg-body rounded
