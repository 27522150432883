// @ts-nocheck
import { Column } from 'react-table'

import { InspectionCreateModifyDateCell } from './InspectionCreateModifyDateCell'
import InspectionNameCell from './InspectionNameCell'
import { InspectionStatusCell } from './InspectionStatusCell'
import { InspectionActionsCell } from './InspectionActionsCell'
import { InspectionCustomHeader } from './InspectionCustomHeader'
import { InspectionValidationCell } from './InspectionValidationCell'
import { Inspection } from '../../core/_models'
import InspectionMobileCell from './InspectionMobileCell'

const inspectionColumns: ReadonlyArray<Column<Inspection>> = [
	{
		Header: (props) => (
			<InspectionCustomHeader tableProps={props} title='ID' className={`text-center min-w-50px`} />
		),
		accessor: 'id',
	},
	{
		Header: (props) => (
			<InspectionCustomHeader tableProps={props} title='Dane samochodu' className={`min-w-300px`} />
		),
		id: 'name',
		Cell: ({ ...props }) => <InspectionNameCell inspection={props.data[props.row.index]} />,
	},
	{
		Header: (props) => (
			<InspectionCustomHeader tableProps={props} title='Data utworzenia/ostatniej modyfikacji' className={`text-center min-w-120px`} />
		),
		id: 'date',
		Cell: ({ ...props }) => <InspectionCreateModifyDateCell inspection={props.data[props.row.index]} />,

	},
	{
		Header: (props) => (
			<InspectionCustomHeader tableProps={props} title='Status' className={`text-center min-w-100px`} />
		),
		id: 'status',
		Cell: ({ ...props }) => <InspectionStatusCell status={props.data[props.row.index].status} />,

	},
	{
		Header: (props) => (
			<InspectionCustomHeader tableProps={props} title='Walidacja inspekcji' className={`text-center min-w-100px`} />
		),
		id: 'valid',
		Cell: ({ ...props }) => <InspectionValidationCell valid={props.data[props.row.index].valid} />,

	},
	{
		Header: (props) => (
			<InspectionCustomHeader tableProps={props} title='Akcje' className={`text-end min-w-150px`} />
		),
		id: 'actions',
		Cell: ({ ...props }) => <InspectionActionsCell inspection={props.data[props.row.index]} />,
	}
]

const inspectionMobileColumn: ReadonlyArray<Column<Inspection>> = [
	{
		Header: (props) => (
			<InspectionCustomHeader tableProps={props} title='' className={``} />
		),
		id: 'id',
		Cell: ({ ...props }) => <InspectionMobileCell inspection={props.data[props.row.index]} />,

	}
]


export { inspectionMobileColumn, inspectionColumns, }
