import React from 'react'
import { QuestionTargetField } from './component/QuestionTargetField'
import { QuestionInformationLabel } from './component/QuestionInformationLabel'
import { useQueryResponseLoading } from './QuestionFormProvider'
import mainClasses from './../../../main.module.css'

const BrakeBlocksBrakeDisc = () => {

	const isLoading = useQueryResponseLoading()

	return (
		<>
			{ !isLoading && <div className='row'>
				<div className='card card-xxl-stretch'>
					{/* begin::Header */}
					<div className='card-header border-0 d-flex text-center w-100 align-content-center justify-content-center'>
						{/* begin::Title */}
						<h3 className={`${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['ifs-23']}`}>4. Klocki i tarcze</h3>

						{/* end::Title */}

						{/* begin::Toolbar */}
						<div className='card-toolbar'>
							{/* begin::Menu */}
							{/* end::Menu */}
						</div>
						{/* end::Toolbar */}
					</div>
					{/* end::Header */}

					<div className='card-body'>
						<QuestionInformationLabel />
						<div className='row d-flex  my-10 align-content-start justify-content-start'>
							<h3 className={`${mainClasses['inspection-text']} ${mainClasses['itext-600']} ${mainClasses['inspection-dark']} ${mainClasses['ifs-14']}`}>1. Stopień zużycia klocków/szczęk hamulcowych</h3>
						</div>

						<QuestionTargetField title="Przód [%]" dictId={141} />
						<QuestionTargetField title="Tył [%]" dictId={143} />

						<div className="separator separator-dashed my-5"></div>

						<div className='row d-flex  my-10 align-content-start justify-content-start'>
							<h3 className={`${mainClasses['inspection-text']} ${mainClasses['itext-600']} ${mainClasses['inspection-dark']} ${mainClasses['ifs-14']}`}>2. Stopień zużycia tarcz hamulcowych/bębnów</h3>
						</div>

						<QuestionTargetField title="Przód [%]" dictId={146} />
						<QuestionTargetField title="Tył [%]" dictId={148} />
					</div>
				</div>
			</div>}
		</>
	)
}

export default React.memo(BrakeBlocksBrakeDisc)

