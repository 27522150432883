import React from 'react'
import { QuestionSelectElement, SelectModel } from './component/QuestionSelectElement'
import { QuestionInputElement } from './component/QuestionInputElement'
import { useQueryResponseLoading } from './QuestionFormProvider'
import mainClasses from './../../../main.module.css'

const TireSizeCondition = () => {

	const tireList = (): Array<SelectModel> => {
		return [
			{
				'id': null,
				'name': 'Wybierz wartość'
			},
			{
				'id': 'lato',
				'name': 'Lato'
			},
			{
				'id': 'zima',
				'name': 'Zima'
			},
			{
				'id': 'wielosezon',
				'name': 'Wielosezonowe'
			}
		];
	}

	const isLoading = useQueryResponseLoading()

	return (
		<>
			{ !isLoading &&
				<div className='row'>
					<div className='card card-xxl-stretch'>
						{/* begin::Header */}
						<div className='card-header border-0 d-flex text-center w-100 align-content-center justify-content-center'>
							{/* begin::Title */}

							<h3 className={`${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['ifs-23']}`}>3. Rozmiar i stan opon</h3>
							{/* end::Title */}
						</div>
						{/* end::Header */}

						<div className='card-body'>
							<div className='row gx-xl-10'>
								<div className='col-12 mb-8 col-xl-6'>
									<QuestionSelectElement name="Typ opon" dictId={66} selectList={tireList()} />
								</div>
								<div className='col-12 mb-8 col-xl-6'>
									<QuestionInputElement title="Rozmiar opon" dictId={67} placeholder="np. 205/65/R15" />
								</div>
							</div>
							<div className='row mb-8 gx-xl-10'>
                                <div className='row'>
                                    <div className='col-6 col-lg-4 col-xl-3 mb-8'>
                                        <QuestionInputElement title="Lewy przód [mm]" dictId={68} />
                                    </div>
                                    <div className='col-6 col-lg-4 col-xl-3 mb-8'>
                                        <QuestionInputElement title="Prawy przód [mm]" dictId={72} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-6 col-lg-4 col-xl-3 mb-8'>
                                        <QuestionInputElement title="Lewy tył [mm]" dictId={70} />
                                    </div>
                                    <div className='col-6 col-lg-4 col-xl-3 mb-8'>
                                        <QuestionInputElement title="Prawy tył [mm]" dictId={74} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-6 col-lg-4 col-xl-3 mb-8'>
                                        <QuestionInputElement title="Stan bieżnika zapasu [mm]" dictId={76} />
                                    </div>
                                </div>
							</div>

						</div>
					</div>
				</div>}
		</>
	)
}

export default React.memo(TireSizeCondition)