import { Dispatch, SetStateAction } from 'react'
import { ID, Response, QueryState } from '../../../../../_metronic/helpers'


export type Inspection = {
	id?: ID

	status?: number

	vin?: string

	mainPhoto?: string

	registrationNumber?: string

	notRegisteredInPoland?: boolean

	productionYear?: number

	mileage?: string | null

	keysNumber?: number

	modelId?: number
	modelName?: string

	companyId?: number
	companyName?: string

	brandId?: number
	brandName?: string

	countryOrigin?: string

	countryOriginName?: string

	fuel?: string

	body?: string
    carPrice?: number
    bodyColor?: string
    carVersion?: string
    vatType?: string
    firstRegistrationDate?: string
	
    createdBy?: string
	updatedBy?: string

	createdAt?: string
	updatedAt?: string

	valid?: boolean
	isValid?: boolean

}

export type SelectModel = {
	id?: string | number
	name?: string
	surname?: string
}

export type FileName = {
	fileName?: string
}

export type SelectModelQueryResponse = Array<SelectModel>
export type InspectionQueryResponse = Response<Array<Inspection>>
export type ResponseInspectionById<T> = T;

export type QueryRequestContextProps = {
	state: QueryState
	updateState: (updates: Partial<QueryState>) => void
}

export type QueryResponseContextProps<T> = {
	response?: SelectModelQueryResponse
	refetch: () => void
	isSelectModelLoading: boolean
	selectedOptionId: ID
	setSelectedOptionId: Dispatch<SetStateAction<ID>>
}

export const initialQueryResponse = { refetch: () => { }, isSelectModelLoading: false, query: '', selectedOptionId: null, setSelectedOptionId: () => {} }

export const initialInspectionQueryResponse = { refetch: () => { }, isLoading: false, query: '' }

export type QueryInspectionResponseContextProps<T> = {
	response?: ResponseInspectionById<T> | undefined
	refetch: () => void
	isLoading: boolean
}

type questionErrorModal = {
	question?: Array<number>
	fields?: Array<string>
}

export type ConfirmResponseModel = {
	success: boolean
	errors?: questionErrorModal
}

export type InspectionConfirmResponse = Response<ConfirmResponseModel>


export const initialInspection: Inspection = {
	status: 1,
	vin: '',
	registrationNumber: '',
	notRegisteredInPoland: false,
	modelId: 0,
	countryOrigin: '',
	countryOriginName: '',
	fuel: '',
	body: '',
	valid: false,
	mileage: '',
	productionYear: new Date().getFullYear(),
	keysNumber: 1
}
