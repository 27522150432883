import React from 'react'

import { QuestionSimpleField } from './component/QuestionSimpleField'
import { QuestionInformationLabel } from './component/QuestionInformationLabel'
import { useQueryResponseLoading } from './QuestionFormProvider'
import mainClasses from './../../../main.module.css'

const Lighting = () => {
	const isLoading = useQueryResponseLoading()

	return (
		<>
			{ !isLoading &&
				<div className='row'>
					<div className='card card-xxl-stretch'>
						<div className='card-header border-0 d-flex text-center w-100 align-content-center justify-content-center'>
							<h3 className={`${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['ifs-23']}`}>6. Oświetlenie</h3>
							<div className='card-toolbar'>
							</div>
						</div>

						<div className='card-body'>
							<QuestionInformationLabel />
							<QuestionSimpleField title="1. Światła pozycyjne" dictId={90} />
							<QuestionSimpleField title="2. Światła postojowe" dictId={91} />
							<QuestionSimpleField title="3. Światła mijania" dictId={92} />
							<QuestionSimpleField title="4. Światła drogowe" dictId={93} />
							<QuestionSimpleField title="5. Światła przeciwmgłowe (przód/tył)" dictId={94} />
							<QuestionSimpleField title="6. Kierunkowskazy (przód/tył)" dictId={95} />
							<QuestionSimpleField title="7. Kierunkowskazy boczne" dictId={96} />
							<QuestionSimpleField title="8. Swiatła awaryjne" dictId={97} />
							<QuestionSimpleField title="9. Oświetlenie tablicy rejestracyjnej" dictId={98} />
							<QuestionSimpleField title="10. Światła stop" dictId={99} />
							<QuestionSimpleField title="11. Światła cofania" dictId={100} />
							<QuestionSimpleField title="12. Oświetlenie bagażnika" dictId={101} />
							<QuestionSimpleField title="13. Oświetlenie wnętrzna pojazdu" dictId={102} />

						</div>
					</div>
				</div>
			}
		</>
	)
}

export default React.memo(Lighting)

