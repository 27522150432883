import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { InspectionListWrapper } from './inspection-list/InspectionList'

const InspectionPage = () => {
	return (
		<Routes>
			<Route element={<Outlet />}>
				<Route
					path='inspection'
					element={
						<>
							<InspectionListWrapper />
						</>
					}
				/>
			</Route>
			<Route index element={<Navigate to='/inspection-management/inspection' />} />
		</Routes>
	)
}

export default InspectionPage
