import { useState, FC } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import * as Yup from 'yup'
import clsx from 'clsx'

import { useFormik } from 'formik'
import { createPassword, updatePassword } from '../core/_requests'
import mainClasses from './../../main.module.css'

type Props = {
	isCreatePassword?: boolean
}

const initialValues = {
	password: '',
	confirmPassword: '',
}

const changePasswordSchema = Yup.object().shape({
	password: Yup.string()
		.min(8, 'Minimum 8 symbole')
		.max(50, 'Maksymalnie 50 symboli')
		.required('Hasło jest wymagane'),
	confirmPassword: Yup.string()
		.min(8, 'Minimum 8 symbole')
		.max(50, 'Maksymalnie 50 symboli')
		.required('Hasło jest wymagane')
	    .oneOf([Yup.ref('password'), null], 'Zapisane hasła nie sa takie same'),

})

const ChangePassword: FC<Props> = ({ isCreatePassword = false }) => {
	const [loading, setLoading] = useState<boolean | undefined>(false)
	const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
	const [hasErrorText, setHasErrorText] = useState<string | undefined>(undefined)

	const { token } = useParams();
    const navigate = useNavigate();

	const formik = useFormik({
		initialValues,
		validationSchema: changePasswordSchema,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			setLoading(true)
			setHasErrors(undefined)
			setTimeout(() => {
				if (isCreatePassword) {
					createPassword(values.password, token)
						.then(({ data: result }) => {
							setHasErrors(false)
							setLoading(false)
						    navigate('/auth', { replace: true });
						})
						.catch((e) => {
							setHasErrors(true)
							setLoading(false)
							setSubmitting(false)
							setStatus('The login detail is incorrect')
						})
				} else {
					updatePassword(values.password, token)
						.then(({ data: result }) => {
							setHasErrors(false)
							setLoading(false)
						    navigate('/auth', { replace: true });
						})
						.catch((error) => {
							if (error.response.data) {
								setHasErrorText(error.response.data)
							}

							setHasErrors(true)
							setLoading(false)
							setSubmitting(false)
							setStatus('The login detail is incorrect')
						})
				}

			}, 200)
		},
	})

	return (
		<form
			className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
			noValidate
			id='kt_login_password_reset_form'
			onSubmit={formik.handleSubmit}
		>
			<div className='text-center mb-10'>
				{/* begin::Title */}
				<h1 className={`mb-3 ${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['inspection-text-27']}`}>
					{isCreatePassword && 'Utwórz hasło'}
					{!isCreatePassword && 'Zmiana hasła'}</h1>
				{/* end::Title */}

				{/* begin::Link */}
				<div className={`${mainClasses['itext-600']} ${mainClasses['inspection-text']} ${mainClasses['inspection-muted-text']} ${mainClasses['ifs-14']}`}>
					Hasło musi się składać z 8 znaków, malej i dużej litery, cyfry, oraz jednego znaku specjalnego
        		</div>
				{/* end::Link */}
			</div>

			{/* begin::Title */}
			{hasErrors === true && (
				<div className='mb-lg-15 alert alert-danger'>
					<div className='alert-text font-weight-bold'>
						{hasErrorText && hasErrorText}
						{!hasErrorText && 'Przepraszamy, wygląda na to, że wykryto jakieś błędy, spróbuj ponownie'}
					</div>
				</div>
			)}

			{hasErrors === false && (
				<div className='mb-10 bg-light-info p-8 rounded'>
					<div className='text-info'>Wysłano hasła.</div>
				</div>
			)}
			{/* end::Title */}

			{/* begin::Form group */}
			<div className='fv-row mb-3'>
				<input
					placeholder=
					{clsx(
						{
							'Hasło': !isCreatePassword,
						},
						{
							'Nowe hasło': isCreatePassword,
						}
					)}
					type='password'
					autoComplete='off'
					{...formik.getFieldProps('password')}
					className={clsx(
						`form-control bg-transparent ${mainClasses['inspection-field']}`,
						{
							'is-invalid': formik.touched.password && formik.errors.password,
						},
						{
							'is-valid': formik.touched.password && !formik.errors.password,
						}
					)}
				/>
				{formik.touched.password && formik.errors.password && (
					<div className='fv-plugins-message-container'>
						<div className='fv-help-block'>
							<span role='alert'>{formik.errors.password}</span>
						</div>
					</div>
				)}
			</div>
			{/* end::Form group */}

			{/* begin::Form group */}
			<div className='fv-row mb-3'>
				<input
					placeholder=
					{clsx(
						{
							'Powtórz hasło': !isCreatePassword,
						},
						{
							'Powtórz nowe hasło': isCreatePassword,
						}
					)}
					type='password'
					autoComplete='off'
					{...formik.getFieldProps('confirmPassword')}
					
					className={clsx(
						`form-control bg-transparent ${mainClasses['inspection-field']}`,
						{
							'is-invalid': formik.touched.password && formik.errors.password,
						},
						{
							'is-valid': formik.touched.password && !formik.errors.password,
						}
					)}
				/>
				{formik.touched.confirmPassword && formik.errors.confirmPassword && (
					<div className='fv-plugins-message-container'>
						<div className='fv-help-block'>
							<span role='alert'>{formik.errors.confirmPassword}</span>
						</div>
					</div>
				)}
			</div>
			{/* end::Form group */}

			{/* begin::Form group */}
			<div className='d-grid mb-10'>
				<button type='submit' id='kt_password_reset_submit' className='btn btn-danger' >

					{!loading && <span className={`${mainClasses['inspection-text']} ${mainClasses['itext-600']} ${mainClasses['ifs-14']}`}>
						{isCreatePassword && 'Utwórz hasło'}
						{!isCreatePassword && 'Zmień hasło'}
					</span>
					}
					{loading && (
						<span className={`indicator-progress ${mainClasses['inspection-text']} ${mainClasses['itext-600']} ${mainClasses['ifs-14']}`} style={{ display: 'block' }}>
							Przetwarzanie...
							<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
						</span>
					)}
				</button>
				{' '}
			</div>
			{/* end::Form group */}
		</form>
	)
}
export { ChangePassword }