import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { Inspection } from '../../core/_models'
import { toAbsoluteUrl, KTSVG } from '../../../../../../_metronic/helpers'
import mainClasses from './../../../../main.module.css'
import { FormattedDate } from 'react-intl'
import SVG from 'react-inlinesvg'
import { useListView } from './../../core/ListViewProvider'

type Props = {
	inspection: Inspection
}

const InspectionMobileCell: FC<Props> = ({ inspection }) => {
	const REACT_APP_URL = process.env.REACT_APP_URL

	const { setOpenModalConfirmDeleteInspection } = useListView()

	return <>
		<div className={'row'}>
			<div className='col-6 py-3 text-start'>
				<span className={`${mainClasses['inspection-text']} ${mainClasses['inspection-muted-text']} ${mainClasses['itext-500']} ${mainClasses['ifs-13']}`}>
					ID:
		   		 </span>
				<span className={`m-2 ${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['ifs-13']}`}>
					{inspection.id}
				</span>
			</div>
			<div className='col-6 text-end'>
				<Link to={`/inspection-form/${inspection.id}`} className={`menu-link me-2`}
					style={{ cursor: 'pointer' }}>
					<SVG
						src={toAbsoluteUrl('/media/inspection/writeRed.svg')}
						style={{ width: '42px', height: '42px' }}
						className='svg-icon-1'
					/>
				</Link>
				<Link to="#" className={`menu-link`} onClick={() => { setOpenModalConfirmDeleteInspection(inspection) }}
					style={{ cursor: 'pointer' }}>
					<SVG
						src={toAbsoluteUrl('/media/inspection/trashFrame.svg')}
						style={{ width: '42px', height: '42px' }}
						className='svg-icon-1'
					/>
				</Link>
			</div>
		</div>
		<div className={'row'}>
			<div className='col-4 py-3 text-start'>
				{inspection.mainPhoto && <img className={`${mainClasses['image-container']}`} style={{ objectFit: 'cover', width: '120px', height: '120px' }} src={toAbsoluteUrl(REACT_APP_URL + "/api/uploads/images/" + inspection.mainPhoto)} />}
				{!inspection.mainPhoto && <img className={`${mainClasses['image-container']}`} style={{ objectFit: 'cover', width: '120px', height: '120px' }} src={toAbsoluteUrl("/media/inspection/banner.svg")} />}
			</div>
			<div className='col-8 px-10 text-start'>
				<div className='row mt-5'>
					<Link to={`/inspection-form/${inspection.id}`} className={`${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['ifs-16']}`}>
						{inspection.brandName} {inspection.modelName}
					</Link>
				</div>
				<div className='row mt-2'>
					<span className={`text-uppercase ${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['itext-500']} ${mainClasses['ifs-13']}`}>{inspection.registrationNumber}</span>
				</div>
				<div className='row mt-2'>
					<span className={`text-uppercase ${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['itext-500']}  ${mainClasses['ifs-13']}`}>{inspection.vin}</span>
				</div>
			</div>
		</div>

		<div className={'row'}>
			<div className='col-6 text-start'>
				<div className={`${mainClasses['inspection-text']} ${mainClasses['inspection-muted-text']} ${mainClasses['itext-500']} ${mainClasses['ifs-13']}`}>
					<p className={'row m-1'}>Data utworzenia/</p>
					<p className={'row m-1'}>ostatniej modyfikacji</p>
				</div>
				<div className={`mt-4 text-start ${mainClasses['inspection-text']}
				${mainClasses['inspection-dark-blue']} 
				${mainClasses['itext-500']} 
				${mainClasses['ifs-13']}`}
				>
					<KTSVG path={"/media/inspection/arhiveLoad.svg"} className="me-2" />
					<FormattedDate value={inspection.createdAt} />
				</div>
				<div className={`${mainClasses['inspection-text']}
					${mainClasses['inspection-dark-blue']} 
					${mainClasses['itext-500']} 
					${mainClasses['ifs-13']}`}>
					<KTSVG path={"/media/inspection/writeGrey.svg"} className="me-2" />
					<FormattedDate value={inspection.updatedAt} />
				</div>
			</div>
			<div className='col-6 text-start'>
				<div className={`mb-3`}>
					<span className={`${mainClasses['inspection-text']}  ${mainClasses['inspection-muted-text']} ${mainClasses['itext-500']} ${mainClasses['ifs-13']}`}>
						Status
					</span>
					<span>
						{inspection.status === 1 && <div style={{ color: '#469CF0' }}>W trakcie</div>}
						{inspection.status === 2 && <div style={{ color: '#76BD64' }}>Zakończone</div>}
						{inspection.status === 3 && <div style={{ color: '#A2A5B5' }}>Usunięty</div>}
					</span>
				</div>
				<div className={``}>
					<span className={`${mainClasses['inspection-text']}  ${mainClasses['inspection-muted-text']} ${mainClasses['itext-500']} ${mainClasses['ifs-13']}`}>
						Walidacja inspekcji
					</span>
					<span>
						{inspection.valid && <div style={{ color: '#469CF0' }}>Spełniona</div>}
						{!inspection.valid && <div style={{ color: '#181C32' }}>Nie spełniona</div>}
					</span>
				</div>
			</div>
		</div>

	</>
}


export default React.memo(InspectionMobileCell)