import React from 'react'

import { QuestionSimpleField } from './component/QuestionSimpleField'
import { QuestionInformationLabel } from './component/QuestionInformationLabel'
import { useQueryResponseLoading } from './QuestionFormProvider'

import { DarkDotItemList } from './../../../UI/DarkDotItemList'
import mainClasses from './../../../main.module.css'

const VehicleTrunk = () => {
	const isLoading = useQueryResponseLoading()

	return (
		<>
			{ !isLoading &&
				<div className='row'>
					<div className='card card-xxl-stretch'>
						{/* begin::Header */}
						<div className='card-header border-0 d-flex text-center w-100 align-content-center justify-content-center'>
							{/* begin::Title */}
							<h3 className={`${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['ifs-23']}`}>9. Bagażnik</h3>
							{/* end::Title */}

							{/* begin::Toolbar */}
							<div className='card-toolbar'>
								{/* begin::Menu */}
								{/* end::Menu */}
							</div>
							{/* end::Toolbar */}
						</div>
						{/* end::Header */}

						<div className='card-body'>
							<QuestionInformationLabel />
							<QuestionSimpleField title="1. Stan wykładziny bagażnika (uszkodzenia, czystość)" dictId={116} />
							<QuestionSimpleField dictId={117}>
								2. Sprawdzić wyposażenie:
									<DarkDotItemList items={['lewarek', 'klucz do kół', 'gaśnica', 'trójkąt', 'kompletność zestawu naprawczego']} />
							</QuestionSimpleField>
							<QuestionSimpleField title="3. Mechanizm otwierania bagażnika od wewnątrz" dictId={118} />
							<QuestionSimpleField title="4. Działanie zamka bagażnika" dictId={119} />

						</div>
					</div>
				</div>}
		</>
	)
}

export default React.memo(VehicleTrunk)

