import { Link } from 'react-router-dom'

import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'

import mainClasses from './../../../../main.module.css';

const InspectionListToolbar = () => {

	return (
		<div className='d-flex justify-content-center justify-content-md-end card-toolbar border-0 pt-6'>
			<Link type='button' className={`btn btn-light-danger w-100 w-md-200px mx-10`} to={`/inspection-form`} >
				<SVG src={toAbsoluteUrl('/media/inspection/plus.svg')} className='svg-icon' />
				<span className={`m-2 ${mainClasses['inspection-text']} ${mainClasses['itext-600']} ${mainClasses['inspection-text-14']}`}>Nowa inspekcja</span>
			</Link>
		</div>
	)
}

export { InspectionListToolbar }