import { FC } from 'react'
import { QuestionInputRadioElement } from './QuestionInputRadioElement'
import mainClasses from './../../../../main.module.css'
import clsx from 'clsx'
import { useSubmitValidationContext } from './../core/InspectionSubmitValidation'

type Props = {
	title?: string,
	dictId: number
}

const QuestionTargetField: FC<Props> = ({ title, dictId }) => {
	const { errorsQuestion } = useSubmitValidationContext()

	return (
		<>
			<div className={clsx(
				`form-control`,
				{
					'is-invalid': errorsQuestion.includes(dictId),
					'border-0 ': !errorsQuestion.includes(dictId),

				}
			)}>
				<div className='row d-flex  mt-8 align-content-start justify-content-start'>
					<h3 className={`${mainClasses['inspection-text']} ${mainClasses['itext-600']} ${mainClasses['inspection-dark']} ${mainClasses['ifs-14']}`}>{title}</h3>
				</div>
				<div className='checkbox-inline'>
					<QuestionInputRadioElement name="10" value="10" dictId={dictId} />
					<QuestionInputRadioElement name="20" value="20" dictId={dictId} />
					<QuestionInputRadioElement name="50" value="50" dictId={dictId} />
					<QuestionInputRadioElement name="70" value="70" dictId={dictId} />
					<QuestionInputRadioElement name="90" value="90" dictId={dictId} />
				</div>
				<div className='d-flex'>
					<QuestionInputRadioElement name="Hamulce bębnowe" value="Hamulce bębnowe" dictId={dictId} />
				</div>
			</div>
		</>
	)
}

export { QuestionTargetField }
