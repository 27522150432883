/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, createContext, useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import {
	ID,
	QUERIES,
	WithChildren,
} from './../../../../../_metronic/helpers'
import { getBrandModel } from './../../inspection-list/core/_requests'
import { SelectModel, QueryResponseContextProps, initialQueryResponse } from './../../inspection-list/core/_models'
import { useQueryBrandResponse } from './BrandListSelectProvider'

function createResponseContext<T>(initialState: QueryResponseContextProps<T>) {
	return createContext(initialState)
}

const QueryResponseContext = createResponseContext<SelectModel>(initialQueryResponse)

const ModelListSelectProvider: FC<WithChildren> = ({ children }) => {
	const { selectedOptionId } = useQueryBrandResponse()
	const brandId = selectedOptionId
	const [query, setQuery] = useState<ID>(brandId)
	const [modelId, setModelId] = useState<ID>()

	useEffect(() => {
		if (query !== brandId) {
			setQuery(brandId)
		}
	}, [brandId])

	const {
		refetch,
		isFetching: modelIsFetching,
		data: response,
	} = useQuery(
		`${QUERIES.BRAND_MODEL_LIST}-${query}`,
		() => {
			return getBrandModel(brandId);
		},
		{ cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false, enabled: !!brandId }
	)

	return (
		<QueryResponseContext.Provider value={{ selectedOptionId: modelId, setSelectedOptionId: setModelId, refetch, response, isSelectModelLoading: modelIsFetching }}>
			{children}
		</QueryResponseContext.Provider>
	)
}

const useQueryModelResponse = () => useContext(QueryResponseContext)


const useModelListSelectData = () => {
	const { response } = useQueryModelResponse()
	if (!response) {
		return []
	}
	return response || []
}

const useQueryModelResponseLoading = (): boolean => {
	const { isSelectModelLoading } = useQueryModelResponse()
	return (isSelectModelLoading)
}

const useModelSelectedOptionId = (): ID => {
	const { selectedOptionId } = useQueryModelResponse()
	return selectedOptionId
}

const useSetModelSelectedOptionId = (id: ID): void => {
	const { setSelectedOptionId } = useQueryModelResponse()
	setSelectedOptionId(id)
}

export {
	ModelListSelectProvider,
	useQueryModelResponse,
	useQueryModelResponseLoading,
	useModelListSelectData,
	useModelSelectedOptionId,
	useSetModelSelectedOptionId
}
