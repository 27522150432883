import { FC, useEffect, useState, useRef } from 'react'
import clsx from 'clsx'

import { createQuestion } from './../core/_requests'
import { useParams } from 'react-router-dom'
import { useResponseDataByDictId } from './../QuestionFormProvider'
import mainClasses from './../../../../main.module.css'
import { useSubmitValidationContext } from './../core/InspectionSubmitValidation'
import { useDebounce } from '../../../../../../_metronic/helpers'

type Props = {
	title?: string,
	dictId: number,
    placeholder?: string
}

const QuestionInputElement: FC<Props> = ({ title, dictId, placeholder }) => {
	const { id } = useParams();
	const fieldData = useResponseDataByDictId(dictId)
	const [value, setValue] = useState(fieldData?.value)

	const { errorsQuestion, setErrorsQuestion } = useSubmitValidationContext()
	const isInitialMount = useRef(true);

	const debouncedValueTerm = useDebounce(value, 550)

	useEffect(
		() => {
			if (debouncedValueTerm !== undefined && value !== undefined) {
				if (isInitialMount.current) {
					isInitialMount.current = false;
				} else {
					createQuestion(String(id).trim(), {
						id: fieldData?.id,
						value: value,
						comment: ''
					})
				}
			}
		},
		[debouncedValueTerm]
	)
	return (
		<>
			<label className={`${mainClasses['inspection-text']} ${mainClasses['itext-600']} ${mainClasses['inspection-dark']} ${mainClasses['ifs-14']}`} htmlFor={`${dictId}`}>{title}</label>

			<div className='d-flex align-content-start justify-content-start'>
				<input type="text" className={clsx(
					`form-control ${mainClasses['input-field']}`,
					{
						'is-invalid': errorsQuestion.includes(dictId),
					}
				)} name={`${dictId}`} id={`${dictId}`} 
                    placeholder={placeholder || ''}
                    autoComplete="off" onChange={(e) => {
					setValue(e.target.value); setErrorsQuestion(errorsQuestion.filter((errorQuestion) => errorQuestion !== dictId))
				}} defaultValue={fieldData?.value} />
			</div>
		</>
	)
}

export { QuestionInputElement }