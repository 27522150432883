import React, { FC, useRef, useEffect } from 'react'
import {
	WithChildren,
} from '../../../../../../_metronic/helpers'
import MenuItem from './MenuItem'

const categories = [
	{ stepNum: 1, name: "Informacje ogólne" },
	{ stepNum: 2, name: "Powłoka lakiernicza" },
	{ stepNum: 3, name: "Rozmiar i stan opon" },
	{ stepNum: 4, name: "Klocki i tarcze" },
	{ stepNum: 5, name: "Wygląd zewnętrzny" },
	{ stepNum: 6, name: "Oświetlenie" },
	{ stepNum: 7, name: "Komora silnika" },
	{ stepNum: 8, name: "Wnętrze pojazdu" },
	{ stepNum: 9, name: "Bagażnik" },
	{ stepNum: 10, name: "Układ kierowniczy i podwozie" },
	{ stepNum: 11, name: "Działanie systemów" },
	{ stepNum: 12, name: "Układ hybrydowy" },
	{ stepNum: 0, name: "Inne" },
]

const HorizontalMenu: FC<WithChildren> = ({ children }) => {
	const containerDiv = useRef<HTMLDivElement | null>(null);
	
	const scrollParent = (leftSize: number) => {
	   containerDiv?.current?.scroll({ top: 0, left: leftSize - 70 });
	};

	return (
		<>
			<div className='stepper stepper-pills d-flex subaside' style={{ top: "80px", zIndex: 3 }}>
				<div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px' >
					<div className=''>
						<div className='d-flex' ref={containerDiv} style={{ scrollBehavior: 'smooth', overflowX: 'scroll', overflowY: "hidden" }}>
							{categories.map((category, i) => (<MenuItem scrollParent={scrollParent} key={('display' + i)} scrollCategoryMenu={true} keyI={('display' + i)} title={category.name} stepNum={category.stepNum} />))}
						</div>
					</div>
				</div>
			</div>
			<div className='mx-auto mw-600px w-100 pt-15 pb-10'>
				{children}
			</div>
		</>
	)
}

export default HorizontalMenu

