import React from 'react'

import { KTSVG } from '../../../../../_metronic/helpers'

import { QuestionSelectElement, SelectModel } from './component/QuestionSelectElement'
import { useQueryResponseLoading } from './QuestionFormProvider'
import mainClasses from './../../../main.module.css'

const ControlPaintCoating = () => {
	const paintCoatingList = (): Array<SelectModel> => {
		return [
			{
				'id': null,
				'name': 'Wybierz wartość'
			},
			{
				'id': '0-80',
				'name': '0-80'
			},
			{
				'id': '80-180',
				'name': '80-180'
			},
			{
				'id': '180-300',
				'name': '180-300'
			},
			{
				'id': '300-750',
				'name': '300-750'
			},
			{
				'id': '>750',
				'name': 'pow. 750'
			}

		];
	}
	const isLoading = useQueryResponseLoading()

	return (
		<>
			{ !isLoading &&
				<div className='row'>
					<div className='card card-xxl-stretch'>
						{/* begin::Header */}
						<div className='card-header border-0 d-flex text-center w-100 align-content-center justify-content-center'>
							{/* begin::Title */}
							<h3 className={`${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['ifs-23']}`}>2. Kontrola powłoki lakierniczej w mikronach</h3>

							{/* end::Title */}
							{/* begin::Toolbar */}
							<div className='card-toolbar'>
								{/* begin::Menu */}
								{/* end::Menu */}
							</div>
							{/* end::Toolbar */}
						</div>
						{/* end::Header */}

						<div className='card-body'>
							<div className='row d-flex text-center w-100 align-content-center justify-content-center mb-8'>
								<KTSVG path="/media/inspection/carSchema.svg" svgClassName={`mh-350px ${mainClasses['schema_coating']}`} />
							</div>
							<div className='row gx-xl-10'>
								<div className='col-xl-3 mb-8  col-6'>
									<QuestionSelectElement name="1. Tylne lewe nadkole" dictId={1001} selectList={paintCoatingList()} />
								</div>
								<div className='col-xl-3 mb-8  col-6'>
									<QuestionSelectElement name="2. Tylne lewe drzwi" dictId={1002} selectList={paintCoatingList()} />
								</div>
								<div className='col-xl-3 mb-8  col-6'>
									<QuestionSelectElement name="3. Przednie lewe drzwi" dictId={1003} selectList={paintCoatingList()} />
								</div>
								<div className='col-xl-3 mb-8  col-6'>
									<QuestionSelectElement name="4. Przednie lewe nadkole" dictId={1004} selectList={paintCoatingList()} />
								</div>

							</div>
							<div className='row gx-xl-10'>
								<div className='col-xl-4 mb-8  col-6'>
									<QuestionSelectElement name="5. Klapa bagażnika" dictId={1005} selectList={paintCoatingList()} />
								</div>
								<div className='col-xl-4 mb-8  col-6'>
									<QuestionSelectElement name="6. Dach" dictId={1006} selectList={paintCoatingList()} />
								</div>
								<div className='col-xl-4 mb-8  col-6'>
									<QuestionSelectElement name="7. Pokrywa silnika" dictId={1007} selectList={paintCoatingList()} />
								</div>
								<div className='col-xl-3 mb-8  col-6'>
									<QuestionSelectElement name="8. Tylne prawe nadkole" dictId={1008} selectList={paintCoatingList()} />
								</div>
								<div className='col-xl-3 mb-8  col-6'>
									<QuestionSelectElement name="9. Tylne prawe drzwi" dictId={1009} selectList={paintCoatingList()} />
								</div>
								<div className='col-xl-3 mb-8  col-6'>
									<QuestionSelectElement name="10. Przednie prawe drzwi" dictId={1010} selectList={paintCoatingList()} />
								</div>
								<div className='col-xl-3 mb-8  col-6'>
									<QuestionSelectElement name="11. Przednie prawe nadkole" dictId={1011} selectList={paintCoatingList()} />
								</div>
							</div>
						</div>
					</div>
				</div>}
		</>
	)
}

export default React.memo(ControlPaintCoating)
