import { useBrandListSelectData, useQueryBrandResponse, useQueryBrandsResponseLoading } from './../../../inspection-detail/core/BrandListSelectProvider'
import { useModelListSelectData, useQueryModelResponse, useQueryModelResponseLoading } from './../../../inspection-detail/core/ModelListSelectProvider'

import { SelectModel } from './../../../inspection-list/core/_models'

const InspectionListFilterModelBrandFields = () => {
	const brandResponseData = useQueryBrandResponse()
	const modelResponseData = useQueryModelResponse()
	
	const brandList = useBrandListSelectData()
	const modelList = useModelListSelectData()

	const isBrandLoading = useQueryBrandsResponseLoading()
	const isModelLoading = useQueryModelResponseLoading()

	const prepareOptionList = (selectList: Array<SelectModel>) => {
		if (!selectList || !Array.isArray(selectList)) {
			return;
		}

		return selectList.map((model) => {
			return <option key={model.id} value={model.id}>{model.name}</option>;
		});
	}

	return <>
		<div className='mb-2'>
			<label className='form-label fs-6 fw-bold'>Marka</label>
			<select disabled={isBrandLoading} value={brandResponseData.selectedOptionId || 0}  className='form-select form-select-solid fw-bolder' onChange={(event: React.ChangeEvent<HTMLSelectElement>) => { brandResponseData.setSelectedOptionId(Number(event.target.value)) }}>
				<option key='0' value={''} />
				{prepareOptionList(brandList)}
			</select>
		</div>
		<div className='mb-2'>
			<label className='form-label fs-6 fw-bold'>Model</label>
			<select disabled={isModelLoading} value={modelResponseData.selectedOptionId || 0} className='form-select form-select-solid fw-bolder' onChange={(event: React.ChangeEvent<HTMLSelectElement>) => { modelResponseData.setSelectedOptionId(Number(event.target.value)) }}>
				<option key='0' value={''} />
				{prepareOptionList(modelList)}
				
			</select>
		</div>
	</>
}

export { InspectionListFilterModelBrandFields }