import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { Inspection } from '../../core/_models'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import mainClasses from './../../../../main.module.css'

type Props = {
	inspection: Inspection
}

const InspectionNameCell: FC<Props> = ({ inspection }) => {
	const REACT_APP_MAIN_IMAGE_URL = process.env.REACT_APP_MAIN_IMAGE_URL
	return <div className='row'>
		<div className='d-flex col-6 col-lg-4'>
			{inspection.mainPhoto && <img className={`${mainClasses['image-container']}`} style={{ objectFit: 'cover' }} loading='lazy' src={toAbsoluteUrl(REACT_APP_MAIN_IMAGE_URL + inspection.mainPhoto)} />}
			{!inspection.mainPhoto && <img className={`${mainClasses['image-container']}`} style={{ objectFit: 'cover' }} src={toAbsoluteUrl("/media/inspection/banner.svg")} />}
		</div>
		<div className='d-flex flex-column text-start col-6 col-lg-8'>
			<Link to={`/inspection-form/${inspection.id}`} className={`${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['ifs-13']}`}>
				{inspection.brandName} {inspection.modelName}
			</Link>
			<span className={`${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['itext-500']} ${mainClasses['ifs-13']}`}>{inspection.registrationNumber}</span>
			<span className={`${mainClasses['inspection-text']} ${mainClasses['inspection-dark-blue']} ${mainClasses['itext-500']} ${mainClasses['ifs-13']}`}>{inspection.vin}</span>
		</div>
	</div>
}


export default React.memo(InspectionNameCell)