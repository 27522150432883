import {FC, useState, Dispatch, SetStateAction } from "react";
import "react-datepicker/dist/react-datepicker.css";

import DatePicker from 'react-datepicker'

type Props = {
	startDate: Date | null
	setDateFrom: Dispatch<SetStateAction<Date | null>>
	endDate: Date | null
	setDateTo: Dispatch<SetStateAction<Date | null>>
}

const InspectionListFilterDatePicker: FC<Props> = ({ startDate, setDateFrom, endDate, setDateTo  }) => {
	return (
		<>
			<div className={'d-flex '}>
				<DatePicker
					className={'w-100px'}
					selected={startDate}
					onChange={(date) => setDateFrom(date)}
					selectsStart
					startDate={startDate}
					endDate={endDate}
					dateFormat={"yyyy-MM-dd"}
				/>
				<DatePicker
					className={'flex-column w-100px'}
					selected={endDate}
					onChange={(date) => setDateTo(date)}
					selectsEnd
					startDate={startDate}
					endDate={endDate}
					minDate={startDate}
					dateFormat={"yyyy-MM-dd"}
				/>
			</div>
		</>
	);
}

export { InspectionListFilterDatePicker }
/* 				excludeDates={[addDays(new Date(), 1), addDays(new Date(), 5)]}
 */